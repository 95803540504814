const Links = () =>{
    const copyFunction = (txt) => {
       // alert(txt);
        navigator.clipboard.writeText(txt).then(() => {
            console.log('Text copied to clipboard');
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    return <>
        <div className="container-fluid mt-5">
            <div className="row">
                <div className="col-md-10 offset-md-1 card">
                <p className="badge bg-primary">Url links</p>
                    <table className="table table-striped text-center">
                        <thead>
                            <th>#</th>
                            <th>Url</th>
                            <th>Details</th>
                            <th>Technology</th>
                            <th>Open</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>https://exam.tmis.in/</td>
                                <td>Front end</td>
                                <td>React,Axios,Redux</td>
                                <td><a href="https://exam.tmis.in/" target="_blank"  rel="noreferrer" className="btn btn-warning btn-sm rounded-0">open</a></td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>https://backend.tmis.in/</td>
                                <td>Api (Only for developers)</td>
                                <td>Laravel,Mysql,Radis</td>
                                <td><a href="https://backend.tmis.in/" target="_blank"  rel="noreferrer" className="btn btn-warning btn-sm rounded-0">open</a></td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>https://mockadmin.tmis.in/</td>
                                <td>Admin panel</td>
                                <td>Php,Mysql,Bootstrap,jquery,chartjs</td>
                                <td><a href="https://mockadmin.tmis.in/" target="_blank"  rel="noreferrer" className="btn btn-warning btn-sm rounded-0">open</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>    

                <div className="col-md-10 offset-md-1 card mt-2">
                <p className="badge bg-info">Sample passwords</p>
                    <table className="table table-striped text-center table-bordered">
                        <thead>
                            <th>#</th>
                            <th>Env</th>
                            <th>Url</th>
                            <th>Details</th>
                            <th>User Id</th>
                            <th>Copy ID</th>
                            <th>Password</th>
                            <th>Copy Pass</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Pro/dev</td>
                                <td>https://exam.tmis.in/</td>
                                <td>Front end</td>
                                <td>aa@gmail.com</td>
                                <td><button className="btn btn-secondary btn-sm rounded-0" onClick={()=>copyFunction('aa@gmail.com')}>Copy</button></td>
                                <td>123456</td>
                                <td><button className="btn btn-secondary btn-sm rounded-0" onClick={()=>copyFunction('123456')}>Copy</button></td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>    
            </div>
        </div>
    </>;
}

export default Links;