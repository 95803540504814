import React from 'react'

export default function My404Component() {
  return (
    <div style={{backgroundColor:''}} className='text-center m-5'>
       <img
              src="/404.webp"
              alt=""
              className="img-fluid" 

            />
    </div>
  )
}
