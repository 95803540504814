import React,{} from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";
// import { NotificationManager } from "react-notifications";
// import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LazyImage from '../components/LazyImage';

export default function Dashboard() {
  // const navigate = useNavigate();

  // const openLinkInNewWindow = (url) => {
  //    // Calculate width and height for fullscreen
  //  const width = window.screen.width;
  //  const height = window.screen.height;

  //  // Open the link in a new fullscreen window without controls and URL bar
  //  window.open(url, '_blank', `toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=${width},height=${height}`);
  // };

  // const handleLinkClick = () => {
  //   const url = '/mocktest'; // Update the URL to your desired destination
  //   openLinkInNewWindow(url);
  // };

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  const user = useSelector((state) => state.auth.user.user);

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
         <div className="text-center">
         <LazyImage
            src={user.profile_pic} // Main image
            placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} // Placeholder image
            errorImage={`${process.env.PUBLIC_URL}/myicons/user.png`} // Error image
            alt="Descriptive text"
            width={70}
            height={70} 
            className="img-fluid rounded"
          />
          {/* <img src={user.profile_pic} className="imgFluid rounded" alt="profile.pic" width="50" height="50" /> */}
          <p className="text-center fw-bold fs-i"> {user.name} </p>
         </div>
          <div className="card">
            <div className="card shadow-lg bg-body-tertiary rounded">
              <div className="card-body p-0">
                <div className={`row text-center p-3 fw-bold bg-${mode} text-${modeText}`}>
                  <div className="col-md-2 col-6">
                    <Link to="/myprofile">
                      <img src="/myicons/user.png" alt="profile" className="dicon" />
                      <p className="dicontext">Profile</p>
                    </Link>
                  </div>
                  <div className="col-md-2 col-6">
                    <Link to="/examwithimage">
                      <img src="/myicons/exam.png" alt="exam" className="dicon" />
                      <p className="dicontext">Quiz</p>
                    </Link>
                  </div>
                  <div className="col-md-2 col-6">
                    <Link to="/purchase">
                      <img src="/myicons/buy.png" alt="purchase" className="dicon" />
                      <p className="dicontext">Purchase</p>
                    </Link>
                  </div>
                  <div className="col-md-2 col-6">
                    <Link to="/history">
                      <img src="/myicons/history.png" alt="history" className="dicon" />
                      <p className="dicontext">History</p>
                    </Link>
                  </div>
                  {/* <div className="col-md-2 col-6">
                    <Link to="/password">
                      <img src="/myicons/password.png" alt="password" className="dicon" />
                      <p className="dicontext">Password</p>
                    </Link>
                  </div> */}
                  <div className="col-md-2 col-6">
                    <Link to="/payments">
                      <img src="/myicons/buy_history.png" alt="payments" className="dicon" />
                      <p className="dicontext">Payments</p>
                    </Link>
                  </div>
                  {/* <div className="col-md-2 col-6"  onClick={handleLinkClick}> */}
                  <div className="col-md-2 col-6">
                    <Link to="/mocktestwithimage">
                    <img src="/myicons/mocktest.png" alt="exam" className="dicon" />
                      <p className="dicontext">Mocktest</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
