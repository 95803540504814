import React, { useEffect, useState } from 'react';
import { useParams,useNavigate} from 'react-router-dom';
import { useSelector } from "react-redux";
import ApiLoading from '../components/ApiLoading';
import ApiError from '../components/ApiError';
import ApiNodata from '../components/ApiNodata';
import PaymentComponent from '../components/PaymentComponent';
import Modal from '../components/Modal';

const truncateText = (text, wordLimit) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(' ') + '...';
};
 
const ExamSets = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const navigate = useNavigate();
    const { id } = useParams();
    const [exams, setExams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedExam, setSelectedExam] = useState(null); // For selected exam
    const [paymentResponse, setPaymentResponse] = useState(null);  //for payment 
    const [isPrModalOpen, setIsPrModalOpen] = useState(false);  //pay receipt
    const [userPayDetailsDB,setUserPayDetailsDB] = useState([]);
    const [detailsData,setDetailsData] = useState([]);

    const token = useSelector((state) => state.auth.user.token);
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    useEffect(() => {
        fetchExams();
    }, [id, token, apiUrl]);

    const fetchExams = async () => {
        try {
            console.log("Fetch sets")
            const response = await fetch(`${apiUrl}/examSets/${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
            // if (!response.ok) {
            //     throw new Error('Network response was not ok');
            // }
            const data = await response.json();
            setExams(data.data || []);
            setUserPayDetailsDB(data.payDetails)
            setDetailsData(data.detailsData)
            // const setPaymentDetails = userPayDetailsDB && userPayDetailsDB.length > 0;
            //console.log(setPaymentDetails)
            //console.log(data.payDetails)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleShowModal = (exam) => {
        setSelectedExam(exam);
        //console.log(exam)
        const modal = new window.bootstrap.Modal(document.getElementById('examModal'));
        modal.show();
        document.body.style.overflow = 'hidden'; 
    };

    const handleCloseModel = async () =>{
        const modal = new window.bootstrap.Modal(document.getElementById('examModal'));
        modal.hide();
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove();
        }
        document.body.style.overflow = ''; 
        setSelectedExam(null);
    };

    const handleGoExam = async (id) =>{
       await handleCloseModel();
        if (id) {
           // navigate(`/instructions?goto=mocktest&id=${id}`);
           navigate(`/instructions`, {
            state: { id: id, goto: 'mocktest' }, // Pass data using state
          });
        }else{
            alert("Some thing wrong...");
        }
    }

    const handlePaymentResponse = (response) => {
        setPaymentResponse(response);
        //console.log("Payment Response:", response);
        if(response.success){
            const modal = new window.bootstrap.Modal(document.getElementById('payModal'));
            modal.hide();
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
            document.body.style.overflow = ''; 
            fetchExams();
            setIsPrModalOpen(true); // Open the modal on successful payment
        }else{
            alert("Failed");
        }
      };

      const handleClosePRModal = () => {
        setIsPrModalOpen(false);
      };
      

    if (loading) return <ApiLoading message={'Loading Sets...'} />;
    if (error) return <ApiError  message={error} />;
    if (exams.length === 0) return(
        <>
            <ApiNodata message="No data available for sets" />
            <p className="text-center"><span className="badge rounded-pill bg-danger" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i>  Back</span></p>
         </>);
   
    return (
        <div className="container mt-4">
            <p className="text-center"><span className="badge rounded-pill bg-danger" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i> Back</span>  &nbsp; &nbsp;  <span className="badge bg-info text-dark">Exam sets</span></p>
            <div className="row">
                <div className={`card rounded mb-3 p-0 m-0 bg-${mode} text-${modeText}`} style={{backgroundColor: '#ADE6BB','lineHeight': '17px',fontFamily:'monospace'}}>
                    <div className="row">
                        <div className="col-md-4 m-auto text-center">
                           <p className="text-center"> {detailsData[0].title} </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <p>Total Sets : {detailsData[0].total_test}</p>
                            <p>Currently showing : {exams.length}</p>
                        </div>
                       
                        <div className="col-md-4 m-auto text-center">
                        <p>Payment status : {userPayDetailsDB[0] && userPayDetailsDB[0].status ? 'Paid' : 'Pending , Purchase to unlock all.'}</p>
                        </div>  
                    </div>
                  
                </div>
                {exams.map((exam) => (
                    <div className="col-md-4 mb-0" key={exam.id} onClick={() => handleShowModal(exam)}>
                        <div className={`card exam-details shadow-lg p-1 mb-5 rounded bg-${mode} text-${modeText}`} style={{ minHeight: 100, backgroundColor: '#ADD8E6',cursor:'pointer' }}>
                            <div className="card-header m-0 p-1"> <h5 className="card-title text-center fw-bold" style={{ fontSize: 15, color: '#CC5500' }}>{exam.set_name}</h5></div>
                            <div className="card-body">
                                {/* <p className="card-text" style={{ fontSize: 12 }}>{truncateText(exam.details, 100)}</p> */}
                                <p className="card-text"
                                    style={{ fontSize: 12 }}
                                    dangerouslySetInnerHTML={{ __html: truncateText(exam.details, 100) }} ></p>

                                <div className={`card py-2 rounded-0 shadow-lg bg-${mode} text-${modeText}`}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            Time <i className="fa-solid fa-clock"></i> : {exam.exam_time} Min
                                            <br />
                                            Total question : {exam.total_question}
                                        </div>
                                        <div className="col-md-6">
                                            Total mark : {exam.total_mark}
                                            <br />
                                            Negative mark : {exam.is_negative_mark === 1 ? 'Yes' : 'No'}
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div className="card-footer text-center m-0 p-1">
                            <div className={`badge bg-${exam.is_paid ? 'success text-warning':'secondary text-dark'} d-inline-block`}>{exam.is_paid ? 'Pro':'Free'}</div> &nbsp;
                            <span className="fw-bold  mt-2" style={{ color: '#A0522D' }} >
                                    Attempt <i className="fa-solid fa-arrow-right-long"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                ))}


        {/* Show payment details if available */}
        {userPayDetailsDB[0] ? '' : (
        
            <div className="p-5 text-center">
                <div className="d-grid gap-2 col-6 mx-auto">

                    <button type="button" className="btn  btn-info rounded-0 p-2 fw-bold" data-bs-toggle="modal" data-bs-target="#payModal">
                    Unlock All Sets
                    </button>
                </div>
                <p>For all {detailsData[0].total_test} sets</p>  
            </div>    
        )}
            </div>

            {/* Modal */}
            <div className="modal fade border" id="examModal" tabIndex="-1" aria-labelledby="examModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl ">
                    <div className={`modal-content rounded-0 bg-${mode} text-${modeText}`} style={{backgroundColor:'#eaf6f6'}}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="examModalLabel">Exam Instruction</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        <p className="text-center h5 mb-3"><u>{selectedExam ?  selectedExam.set_name : ''}</u></p>
                        <div className="row">
                            <div className="col-md-2 text-center">
                                <img src="/myicons/user-guide.png" alt={`loading`} className="img-fluid" width="100" height="100" />
                             </div>
                            <div className="col-md-10">
                            <strong className="text-success m-1">Details :</strong>
                            <p className="card-text"  style={{ fontSize: 12 }}
                                dangerouslySetInnerHTML={{
                                    __html: selectedExam ? selectedExam.details : '-'
                                }}></p>
                            <strong className="text-success m-1">Instructions :</strong>
                            <p className="card-text"  style={{ fontSize: 12 }}
                                dangerouslySetInnerHTML={{
                                    __html: selectedExam ? selectedExam.instructions : '-'
                                }}></p>

                              {/* {selectedExam ? selectedExam.instructions : ''} */}
                            </div>
                        </div>
                            
                            <br /><br />
                        <div className="row">
                            <div className="col-md-9"  style={{ fontSize: 13,lineHeight:2 }}>
                                Time <i className="fa-solid fa-clock text-danger"></i> : {selectedExam ?  selectedExam.exam_time : ''} Min
                                <br />
                                Total question : {selectedExam ? selectedExam.total_question : ''}
                                <br />
                                Total mark : {selectedExam ? selectedExam.total_mark : ''}
                                <br />
                                Negative mark : {selectedExam ? selectedExam.is_negative_mark === 1 ? 'Yes' : 'No'  : ''}
                                <br />
                                Per question Negative mark : {selectedExam ? selectedExam.per_question_negative_mark  : ''}
                            </div>
                            <div className="col-md-3 text-center">
                            <img src="/myicons/time-mark.jpg" alt={`loading`} className="img-fluid" width="180" height="180" />
                            </div>
                        </div>
                        <p className="mb-0 mt-1"><input type="checkbox" checked readOnly/> Accept all terms & conditions for this exam.</p>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary rounded-0" onClick={() => handleGoExam(selectedExam.id) }>
                                Go to Exam
                            </button>
                            <button type="button" className="btn btn-secondary rounded-0" data-bs-dismiss="modal" onClick={() => handleCloseModel() }>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        {/* ----Pay modal----- */}

        {userPayDetailsDB[0] ? '' : (

            <div className="modal fade" id="payModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className={`modal-content bg-${mode} text-${modeText}`}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Payment</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-center">
                <p>For all {detailsData[0].total_test} sets , Rs : {detailsData[0].price}</p>
                    <PaymentComponent amount={detailsData[0].price} onPaymentComplete={handlePaymentResponse} payfor="sets" payforid={id}  />
                    <img src="/image/click-here.gif" alt={`clickhere`} className="img-fluid m-2" width="50" />
                    <br/>
                    {/* <img src="/image/ssl.webp" alt={`ssl`} className="img-fluid m-2" width="200" /> */}
                    <img src="/image/razorpay.webp" alt={`razorpay`} className="img-fluid" />
                </div>
                </div>
            </div>
            </div>    

        )}

            <Modal isOpen={isPrModalOpen} onClose={handleClosePRModal} receipt={paymentResponse} />

        </div>
    );
};

export default ExamSets;
