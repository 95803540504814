import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
// import "./allPages.css";
import { useSelector } from 'react-redux'
import ApiLoading from '../components/ApiLoading'; 
import ApiError from '../components/ApiError';
import ApiNodata from '../components/ApiNodata';
import LazyImage from '../components/LazyImage';

const apiUrl = process.env.REACT_APP_API_BASE_URL;
const dimageBaseUrl = process.env.REACT_APP_DETAILS_IMAGE_BASE_URL;

const truncateText = (text, wordLimit) => {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(' ') + '...';
};

const ExamDetails = () => {
    const { id } = useParams(); // Get the ID from the URL
    const navigate = useNavigate();
    const [exams, setExams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    useEffect(() => {
        const fetchExams = async () => {
            try {
                const response = await fetch(`${apiUrl}/examDetails/${id}`); // Fetch all exams
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setExams(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExams();
    }, [id]);


    const handleNext = (id) =>{
        if (id) { 
            if(isAuthenticated){
                navigate(`/examSets/${id}`);
            }else{
                navigate(`/login?type=examSets&id=${id}&token=${Math.floor(Math.random() * 1000000)}&goto=/examSets/${id}`);
            }
        }
    }


    if (loading) return <ApiLoading message={'Loading details...'} />;
    if (error) return <ApiError  message={error} />;
    if (exams.length === 0) return(
        <>
            <ApiNodata message="No data available for details" />
            <p className="text-center"><span className="badge rounded-pill bg-danger" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i>  Back</span></p>
         </>);

    return (
        <div className="container mt-4">
          <p className="text-center"><span className="badge rounded-pill bg-danger" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i>  Back</span>  &nbsp; &nbsp;  <span className="badge  bg-success">Exam Details</span></p>
            <div className="row">
                {exams.map((exam) => (
                    <div className="col-md-4 mb-1" key={exam.id} style={{}} > 
                        <div onClick={() => handleNext(exam.id)} key={exam.id} style={{cursor:'pointer'}}>
                            <div className={`card exam-details shadow-lg m-1 p-3 mb-2 bg-${mode} text-${modeText}`} style={{ backgroundColor: '#F0F8FF','overflowX':'hidden'}}>

                                <div className="row">
                                    <div className="col-6">
                                    <LazyImage
                                        src={dimageBaseUrl + exam.image} 
                                        placeholder={`${process.env.PUBLIC_URL}/image/loading2.gif`} 
                                        errorImage={`${process.env.PUBLIC_URL}/myicons/no-image.png`}
                                        alt="Descriptive text"
                                        width={200}
                                        height={100} 
                                        className="img-fluid p-1 rounded img-thumbnail"
                                    />
                                    </div>
                                    <div className="col-6 text-center">
                                        <span className="badge bg-secondary">Rating :{'5 *'}</span>
                                        <br />
                                        <span className="badge bg-primary">Register : {exam.register_count ? exam.register_count : 'No user'}</span>
                                        <br />
                                        <span className="badge bg-info">{new Date(exam.last_update).toLocaleString()}</span>
                                        <span className="badge bg-success">Free Set: {exam.total_free_test}</span> 
                                        &nbsp;
                                        <span className="badge bg-warning">Paid : {exam.total_test - exam.total_free_test }</span>
                                    </div>
                                </div>
                                <div className="card-body m-0 pb-0">
                                    <h5 className="card-title text-center fw-bold" style={{ fontSize: 15, color: '#007791' }}>{exam.title}</h5>
                                    <p className="card-text" style={{ fontSize: 12 }}> {truncateText(exam.details, 30)}</p>
                                    {/* <p className="card-text"><strong>Key Points:</strong> {truncateText(exam.key_points, 25)}</p> */}
                                
                                 <p className="fw-bold text-center m-0 p-0" style={{color: 'tomato' }}>View <i className="fa-solid fa-arrow-right-long"></i></p>    
                                </div>      
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExamDetails;
