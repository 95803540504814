import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useNavigate, Link ,useLocation } from "react-router-dom";
import queryString from 'query-string';
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { useSelector,useDispatch } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import { login } from '../redux/auth/authSlice';
import Loader from "../components/Loader";

function Register() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation();
  const currentParams = queryString.parse(location.search); // Get current query parameters
  const { goto }  = currentParams;
   // Construct the query string for the link
   const queryStringParams = queryString.stringify(currentParams);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    buttonName: "Register",
  });
  const [loading, setLoading] = useState(false);
  const [errmessage, setErrMessage] = useState("");
  const [successmessage, setSuccessMessage] = useState("");
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrMessage("");
    if (state.name === "") {
      setErrMessage("Please input name");
      return;
    }
    if (state.email === "") {
      setErrMessage("Please input email");
      return;
    }
    if (state.password === "") {
      setErrMessage("Please input password");
      return;
    }
    // Disable the button
    document.getElementById("sbmtbtn").disabled = true;

    setLoading(true);

    // Perform axios request here
    const data = {
      name: state.name,
      email: state.email,
      password: state.password,
    };
    axios
      .post(`${apiUrl}/register`, data)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        document.getElementById("sbmtbtn").disabled = false;
        if (response.data.success === true) {
          NotificationManager.success(
            "Redirecting to Login page...",
            "Register success"
          );
          if( goto){
            navigate(goto, { replace: true });
          }else{
            navigate("/login", { replace: true });
          }
         
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        setLoading(false);
        document.getElementById("sbmtbtn").disabled = false;
        NotificationManager.error(error.response.data.message, "Error");
      });

  };

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    // If the user is logged in, redirect to the dashboard
    if (isAuthenticated) navigate("/dashboard", { replace: true });


  //For google register
    const handleGoogleSuccess = async (credentialResponse) => {
      const tokenId = credentialResponse.credential;
      setLoading(true);
      try {
        const response = await axios.post(`${apiUrl}/google-login`, { tokenId });
        NotificationManager.success("Registration successful!", "Success");
        const provide = response.data.provider;
        setLoading(false);
        //console.log(response.data)
        if (goto) {
          navigate(goto, { replace: true });
        } else {
          if(provide!='self'){
            NotificationManager.success(
              "Redirecting to dashboard page...",
              "Login success"
            );
            const userData = response.data.data;
            const token = response.data.token;
           dispatch(login({ user: userData, token }));
            navigate("/dashboard", { replace: true });
          }else{
            navigate("/login", { replace: true });
          }
          
        }
      } catch (error) {
        setLoading(false);
        NotificationManager.error("Google registration failed", "Error");
        console.error(error);
      }
    };

    const handleGoogleFailure = (error) => {
      NotificationManager.error("Google login failed", "Error");
      console.error("Google login failed:", error);
    };
  

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-4 offset-md-4 mt-5 card bg-${mode} text-${modeText} shadow-lg p-3 mb-5 rounded`}
          >
            <form onSubmit={handleSubmit}>
              <h3 className="m-3  text-center"> Register Here </h3>
              <label htmlFor="username"> Name </label> <br />
              <input
                className="form-control"
                type="text"
                placeholder="name"
                id="username"
                name="name"
                value={state.name}
                onChange={handleInputChange}
              />
              <br />
              <label htmlFor="email"> Email </label> <br />
              <input
                className="form-control"
                type="email"
                placeholder="Email"
                id="email"
                name="email"
                value={state.email}
                onChange={handleInputChange}
              />
              <br />
              <label htmlFor="password"> Password </label> <br />
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                name="password"
                value={state.password}
                onChange={handleInputChange}
              />
              <br />
              <button className="btn btn-success" id="sbmtbtn" type="submit">
                {/* {state.buttonName} */}
                Register
              </button>
              <br />
              <div className="mt-3">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  logo_alignment="left"
                />
              </div>
              {/* Show loader if buttonName is "Submitting..." */}
              {loading && 
                //  <div className="loader">Loading...</div>
                <Loader /> 
              }
              <span className="text-danger">{errmessage}</span>
              <span
                className="text-success"
                dangerouslySetInnerHTML={{ __html: successmessage }}
              />
              <br />
            </form>
            <Link className={`alink text-${modeText}`} to={`/login?${queryStringParams}`}>
              Already registered ? Login
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
