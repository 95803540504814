import React, { useState, useEffect, memo } from "react";
import "./exam.css";
import "./examwithimage.css";
import { useNavigate } from "react-router-dom";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
// import questions from "../questions.json";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import axios from "axios";
import { useSelector } from "react-redux";

function ExamWithImage() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [examtime, setExamtime] = useState(910000);
  const [timerBlinking, setTimerBlinking] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [attemptedQuestionsId, setAttemptedQuestionsId] = useState([]);
  const [skipQuestionId, setSkipQuestionId] = useState([]);

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
 // const isAuthenticated = useSelector(state => state.auth.user.token);
 const token = useSelector(state => state.auth.user.token);

  const serverImagePath = process.env.REACT_APP_CATEGORY_IMAGE_BASE_URL;
  // const serverImagePath = "https://exam.tmis.in/backend/public/storage/uploads/";

  // For storing result
  const [userResponses, setUserResponses] = useState({});

  const handleUserResponse = (questionId, selectedOption, DBquestionId) => {
    //set active selected option
    setSelectedOption(selectedOption);
    //marked question as attempt
    attemptQuestion(questionId);

    setUserResponses((prevResponses) => ({
      ...prevResponses,
      // [questionId]: selectedOption,
      [DBquestionId]: selectedOption,
    }));
  };

  //Add in attempt question array
  const attemptQuestion = (questionId) => {
    setAttemptedQuestionsId((attemptedQuestionsId) => {
      // Check if the questionId already exists in the array
      if (!attemptedQuestionsId.includes(questionId)) {
        // If it doesn't exist, insert the questionId
        return [...attemptedQuestionsId, questionId];
      }
      // If it already exists, return the current array unchanged
      return attemptedQuestionsId;
    });
  };

  //getting count of attempt question
  const uniqueLengthAttemptedQuestion = [...new Set(attemptedQuestionsId)]
    .length;

  //check skip question and add in skip question array
  const skipQuestion = (questionId) => {
    if (attemptedQuestionsId.includes(questionId)) {
      //skip question attempt later then it remove from skip and add in attempt
      attemptQuestion(questionId);

      let indexToRemove;
      while ((indexToRemove = skipQuestionId.indexOf(questionId)) !== -1) {
        skipQuestionId.splice(indexToRemove, 1);
      }
    } else {
      //console.log(questionId);
      //add in skip list
      if (!skipQuestionId.includes(questionId)) {
        setSkipQuestionId((skipQuestionId) => [...skipQuestionId, questionId]);
      }
    }
  };

  //getting count of Skip question
  const uniqueLengthSkipQuestion = [...new Set(skipQuestionId)].length;

  // Next button
  const nextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedOption("");
    //skip question section
    skipQuestion(currentQuestionIndex + 1);
  };

  // Previous button
  const previousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedOption("");
  };

  // Submit button
  const submitFun = () => {
    calculate();
    NotificationManager.success("Calculating result...", "Exam end");
  };

  //Timer end
  const timeEndFunction = () => {
    calculate();
    NotificationManager.warning("Calculating result...", "Exam time end");
  };

  //calculation
  const calculate = async () => {
    //send responce to backend
   // const token =   localStorage.getItem("accessToken"); // Replace 'YOUR_BEARER_TOKEN' with your actual token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    await axios
      .post(`${apiUrl}/result`, { userResponses },config)
      .then((response) => {
        // Handle response if needed
        console.log(response.data);
        if (response.data.success === true) {
          const resultData = {
            totalQuestions: questionData.length,
            attemptedQuestions: attemptedQuestionsId.length,
            skippedQuestions: skipQuestionId.length,
            correctAnswers: response.data.result.correctAnswers,
            incorrectAnswers: response.data.result.incorrectAnswers,
          };
          navigate("/examResult", { state: resultData });
        } else {
          alert("Failed to submit");
        }
      })
      .catch((error) => {
        // Handle error if needed
        console.error(error);
      });
  };

  // //prevent reload , back
  // useEffect(() => {
  //   const preventNavigation = (event) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };

  //   window.addEventListener("popstate", preventNavigation);
  //   window.onbeforeunload = () => ""; // For handling refresh

  //   return () => {
  //     window.removeEventListener("popstate", preventNavigation);
  //     window.onbeforeunload = null;
  //   };
  // }, []);

  // Load questions
  // useEffect(() => {
  //   // setQuestionData(questions);
  //   const fetchQuestion = async () => {
  //     await axios.get(`http://localhost:8000/api/iquiz`).then((res) => {
  //       // console.log(res);
  //       // console.log("q data", res.data);
  //       // console.log(new Date());
  //       setQuestionData(res.data);
  //     });
  //   };
  //   fetchQuestion();
  // }, []);
  useEffect(() => {
    const fetchQuestion = async () => {
      try {
       // const token =   localStorage.getItem("accessToken"); // Replace 'YOUR_BEARER_TOKEN' with your actual token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
  
        const res = await axios.get(`${apiUrl}/iquiz`, config);
        setQuestionData(res.data);
      } catch (error) {
        console.error('Error fetching questions:', error);
      }
    };
  
    fetchQuestion();
  }, []);

  //axios request to get question

  return (
    <div className="container-fluid">
       <p>Exam</p>
      <div className="container-fluid mt-md-5 mt-1">
        <div className="row">
          <div
            className={`col-md-2 h-25 d-inline-block text-center shadow-lg p-md-5 p-1 mb-1 rounded bg-${mode} text-${modeText}`}
          >
            <div className="row">
              <div className="col-md-12 col-5">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Remaining time
                </p>
                <CountdownCircleTimer
                  isPlaying
                  duration={examtime}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  size={150}
                  colorsTime={[7, 5, 2, 0]}
                  isGrowing={false}
                  onComplete={timeEndFunction}
                >
                  {({ remainingTime }) => {
                    const hours = Math.floor(remainingTime / 3600);
                    const minutes = Math.floor((remainingTime % 3600) / 60);
                    const seconds = remainingTime % 60;

                    if (remainingTime < 60) {
                      setTimerBlinking(true);
                    } else {
                      setTimerBlinking(false);
                    }

                    return (
                      <div
                        className={` rounded m-1 ${
                          timerBlinking ? "blink" : ""
                        }`}
                      >
                        <p className="text-center fw-bold">
                          ⏲ <br />
                          <p>
                            {`${hours} hour `} <br />
                            {`${minutes} minute `} <br />
                            {`${seconds} second`}
                          </p>
                        </p>
                      </div>
                    );
                  }}
                </CountdownCircleTimer>
              </div>
              <div className="col-7 d-block d-md-none m-auto shadow-sm p-1">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Summery
                </p>
                <p className="badge bg-primary m-1 h4">
                  Total Question : {questionData.length}
                </p>
                <p className="badge bg-success m-1 h4">
                  Attempt Question : {uniqueLengthAttemptedQuestion}
                </p>
                <p className="badge bg-warning m-1 text-dark h4">
                  UnAttempt Question :
                  {questionData.length -
                    (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                </p>
                <p className="badge bg-danger m-1 h4">
                  Skip Question : {uniqueLengthSkipQuestion}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div
              className={`card shadow-lg  mb-2 rounded  bg-${mode} text-${modeText}`}
            >
              <div className="card-body">
                <div className="question-section">
                  {questionData.length > 0 && (
                    <>
                      <p className="question">
                        {currentQuestionIndex + 1}){/* //load question start */}
                        {questionData[currentQuestionIndex].question}
                        {/* //load question end */}
                        {/* //load question details start */}
                        <div className="questionDescription text-center">
                          {questionData[currentQuestionIndex]
                            .question_desc_isImage === 1 ? (
                            <img
                            className="img-fluid h-25 d-inline-block qdescimage"
                              src={`${
                                serverImagePath +
                                questionData[currentQuestionIndex].question_desc
                              }`}
                              alt="Question Image"
                            />
                          ) : (
                            <p>
                              {questionData[currentQuestionIndex].question_desc}
                            </p>
                          )}
                        </div>
                        {/* //load question details end */}
                      </p>

                      {/* ------------Option section start ------------------  */}
                      <div className="container-fluid question-container">
                        <div className="row option-section">
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "A" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="A"
                              checked={selectedOption === "A"}
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "A",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />&nbsp;
                            i) {questionData[currentQuestionIndex]
                            .optionA_isImage === 1 ? (
                            <img
                            className="img-fluid h-25 d-inline-block qdescimage"
                              src={`${
                                serverImagePath +
                                questionData[currentQuestionIndex].optionA
                              }`}
                              alt="Question Image"
                            />
                          ) : (
                            <span>
                              {questionData[currentQuestionIndex].optionA}
                            </span>
                          )}
                          </label>
                          <label
                            className={`option2 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "B" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="B"
                              checked={selectedOption === "B"}
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "B",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />&nbsp;
                            ii){questionData[currentQuestionIndex]
                            .optionB_isImage === 1 ? (
                            <img
                            className="img-fluid h-25 d-inline-block qdescimage"
                              src={`${
                                serverImagePath +
                                questionData[currentQuestionIndex].optionB
                              }`}
                              alt="Question Image"
                            />
                          ) : (
                            <span>
                              {questionData[currentQuestionIndex].optionB}
                            </span>
                          )}
                          </label>
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "C" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="C"
                              checked={selectedOption === "C"}
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "C",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            /> &nbsp;
                            iii) {questionData[currentQuestionIndex]
                            .optionC_isImage === 1 ? (
                            <img
                            className="img-fluid h-25 d-inline-block qdescimage"
                              src={`${
                                serverImagePath +
                                questionData[currentQuestionIndex].optionC
                              }`}
                              alt="Question Image"
                            />
                          ) : (
                            <span>
                              {questionData[currentQuestionIndex].optionC}
                            </span>
                          )}
                          </label>
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "D" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="D"
                              checked={selectedOption === "D"}
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "D",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />&nbsp;
                            iv) {questionData[currentQuestionIndex]
                            .optionD_isImage === 1 ? (
                            <img
                            className="img-fluid h-25 d-inline-block qdescimage"
                              src={`${
                                serverImagePath +
                                questionData[currentQuestionIndex].optionD
                              }`}
                              alt="Question Image"
                            />
                          ) : (
                            <span>
                              {questionData[currentQuestionIndex].optionD}
                            </span>
                          )}
                          </label>
                        </div>
                      </div>

                      {/* ------------Option section end ------------------  */}
                      <div className="qbuttons text-center">
                        {currentQuestionIndex > 0 && (
                          <button
                            id="backbtn"
                            className="mybtn backbtn"
                            onClick={previousQuestion}
                          >
                            <i className="fa-solid fa-chevron-left"></i>
                            Previous
                          </button>
                        )}
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="nextbtn"
                            className="mybtn nextbtn"
                            onClick={nextQuestion}
                          >
                            Next <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}
                        {/* {currentQuestionIndex === questionData.length - 1 && ( */}
                        {currentQuestionIndex > 0 && (
                          <button
                            id="submitbtn"
                            className="mybtn submitbtn"
                            onClick={submitFun}
                          >
                            <i className="fa-solid fa-check"></i> Submit
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="card-footer text-center">
                {/* <div className="bg-primary">
                  <p>Attempted</p>
                  {JSON.stringify(attemptedQuestionsId)}
                </div>
                <div className="bg-success">
                  <p>Skipped</p>
                  {JSON.stringify(skipQuestionId)}
                </div>
                <div className="bg-danger">
                  <p>User response</p>
                  {JSON.stringify(userResponses)}
                </div> */}

                <p className="d-none d-md-block">
                  <span className="badge bg-primary m-1  h4">
                    Total Question : {questionData.length}
                  </span>
                  <span className="badge bg-success m-1  h4">
                    Attempt Question : {uniqueLengthAttemptedQuestion}
                  </span>
                  <span className="badge bg-warning text-dark m-1  h4">
                    Un Attempt Question :
                    {questionData.length -
                      (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                  </span>
                  <span className="badge bg-danger m-1  h4">
                    Skip Question : {uniqueLengthSkipQuestion}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default ExamWithImage;
