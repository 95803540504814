import React, { useState, useEffect } from "react";
import "./exam.css";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import questions from "../questions.json";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

function ExamStatic(props) {
  const navigate = useNavigate();
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [examtime, setExamtime] = useState(910000);
  const [timerBlinking, setTimerBlinking] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [attemptedQuestionsId, setAttemptedQuestionsId] = useState([]);
  const [skipQuestionId, setSkipQuestionId] = useState([]);

  // For storing result
  const [userResponses, setUserResponses] = useState({});

  const handleUserResponse = (questionId, selectedOption) => {
    //set active selected option
    setSelectedOption(selectedOption);
    //marked question as attempt
    attemptQuestion(questionId);

    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: selectedOption,
    }));
  };

  //Add in attempt question array
  const attemptQuestion = (questionId) => {
    setAttemptedQuestionsId((attemptedQuestionsId) => {
      // Check if the questionId already exists in the array
      if (!attemptedQuestionsId.includes(questionId)) {
        // If it doesn't exist, insert the questionId
        return [...attemptedQuestionsId, questionId];
      }
      // If it already exists, return the current array unchanged
      return attemptedQuestionsId;
    });
  };

  //getting count of attempt question
  const uniqueLengthAttemptedQuestion = [...new Set(attemptedQuestionsId)]
    .length;

  //check skip question and add in skip question array
  const skipQuestion = (questionId) => {
    if (attemptedQuestionsId.includes(questionId)) {
      //skip question attempt later then it remove from skip and add in attempt
      attemptQuestion(questionId);

      let indexToRemove;
      while ((indexToRemove = skipQuestionId.indexOf(questionId)) !== -1) {
        skipQuestionId.splice(indexToRemove, 1);
      }
    } else {
      //console.log(questionId);
      //add in skip list
      setSkipQuestionId((skipQuestionId) => [...skipQuestionId, questionId]);
    }
  };

  //getting count of Skip question
  const uniqueLengthSkipQuestion = [...new Set(skipQuestionId)].length;

  // Load questions
  useEffect(() => {
    setQuestionData(questions);
  }, []);

  // Next button
  const nextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedOption("");
    //skip question section
    skipQuestion(currentQuestionIndex + 1);
  };

  // Previous button
  const previousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedOption("");
  };

  // Submit button
  const submitFun = () => {
    const data = calculate();
    NotificationManager.success("Calculating result...", "Exam end");
    navigate("/examResult", { state: data });
  };

  //Timer end
  const timeEndFunction = () => {
    const data = calculate();
    NotificationManager.warning("Calculating result...", "Exam time end");
    navigate("/examResult", { state: data });
  };

  //calculation
  const calculate = () => {
    let attemptedQuestions = Object.keys(userResponses);
    // Initialize counters
    let correctAnswers = 0;
    let incorrectAnswers = 0;

    // Iterate through each question
    questions.forEach((question) => {
      // Check if the question ID exists in userResponses
      if (userResponses.hasOwnProperty(question.id)) {
        // Check if the user's answer matches the correct answer
        if (userResponses[question.id] === question.answer) {
          correctAnswers++;
        } else {
          incorrectAnswers++;
        }
      } else {
        // If the question is unattempted, increment incorrectAnswers (could be handled differently based on your logic)
        //  incorrectAnswers++;
      }
    });

    const resultData = {
      totalQuestions: questionData.length,
      attemptedQuestions: attemptedQuestionsId.length,
      skippedQuestions: skipQuestionId.length,
      correctAnswers,
      incorrectAnswers,
    };
    return resultData;
  };

  useEffect(() => {
    const preventNavigation = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("popstate", preventNavigation);
    window.onbeforeunload = () => ""; // For handling refresh

    return () => {
      window.removeEventListener("popstate", preventNavigation);
      window.onbeforeunload = null;
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="container-fluid mt-md-5 mt-1">
        <div className="row">
          <div
            className={`col-md-2 m-auto text-center shadow-lg p-md-5 p-1 mb-1 rounded bg-${props.mode} text-${props.modeText}`}
          >
            <div className="row">
              <div className="col-md-12 col-5">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Remaining time
                </p>
                <CountdownCircleTimer
                  isPlaying
                  duration={examtime}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  size={150}
                  colorsTime={[7, 5, 2, 0]}
                  isGrowing={false}
                  onComplete={timeEndFunction}
                >
                  {({ remainingTime }) => {
                    const hours = Math.floor(remainingTime / 3600);
                    const minutes = Math.floor((remainingTime % 3600) / 60);
                    const seconds = remainingTime % 60;

                    if (remainingTime < 60) {
                      setTimerBlinking(true);
                    } else {
                      setTimerBlinking(false);
                    }

                    return (
                      <div
                        className={` rounded m-1 ${
                          timerBlinking ? "blink" : ""
                        }`}
                      >
                        <p className="text-center fw-bold">
                          ⏲ <br />
                          <p>
                            {/* {hours !== 0 && `${hours} hour `} <br />
                            {minutes !== 0 && `${minutes} minute `} <br />
                            {seconds !== 0 && `${seconds} second`} */}
                            {`${hours} hour `} <br />
                            {`${minutes} minute `} <br />
                            {`${seconds} second`}
                          </p>
                        </p>
                      </div>
                    );
                  }}
                </CountdownCircleTimer>
              </div>
              <div className="col-7 d-block d-md-none m-auto shadow-sm p-1">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Summery
                </p>
                <p className="badge bg-primary m-1 h4">
                  Total Question : {questionData.length}{" "}
                </p>
                <p className="badge bg-success m-1 h4">
                  {" "}
                  Attempt Question : {uniqueLengthAttemptedQuestion}{" "}
                </p>
                <p className="badge bg-warning m-1 text-dark h4">
                  {" "}
                  UnAttempt Question :
                  {questionData.length -
                    (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                </p>
                <p className="badge bg-danger m-1 h4">
                  {" "}
                  Skip Question : {uniqueLengthSkipQuestion}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div
              className={`card shadow-lg  mb-2 rounded  bg-${props.mode} text-${props.modeText}`}
            >
              {/* <div className="card-header">
                Exam (Total question : {questionData.length})
              </div> */}
              <div className="card-body">
                <div className="question-section">
                  {questionData.length > 0 && (
                    <>
                      <p className="question">
                        {currentQuestionIndex + 1}){" "}
                        {questionData[currentQuestionIndex].question}
                      </p>
                      <div className="option-section">
                        <p
                          className={`option1 options ${
                            selectedOption === "A" ? "active" : ""
                          }`}
                          onClick={() =>
                            handleUserResponse(
                              // questionData[currentQuestionIndex].id,
                              currentQuestionIndex + 1,
                              "A"
                            )
                          }
                        >
                          i) {questionData[currentQuestionIndex].A}
                        </p>
                        <p
                          className={`option2 options ${
                            selectedOption === "B" ? "active" : ""
                          }`}
                          onClick={() =>
                            handleUserResponse(
                              // questionData[currentQuestionIndex].id,
                              currentQuestionIndex + 1,
                              "B"
                            )
                          }
                        >
                          ii) {questionData[currentQuestionIndex].B}
                        </p>
                        <p
                          className={`option1 options ${
                            selectedOption === "C" ? "active" : ""
                          }`}
                          onClick={() =>
                            handleUserResponse(
                              // questionData[currentQuestionIndex].id,
                              currentQuestionIndex + 1,
                              "C"
                            )
                          }
                        >
                          iii) {questionData[currentQuestionIndex].C}
                        </p>
                        <p
                          className={`option1 options ${
                            selectedOption === "D" ? "active" : ""
                          }`}
                          onClick={() =>
                            handleUserResponse(
                              // questionData[currentQuestionIndex].id,
                              currentQuestionIndex + 1,
                              "D"
                            )
                          }
                        >
                          iv) {questionData[currentQuestionIndex].D}
                        </p>
                      </div>
                      <div className="qbuttons text-center">
                        {currentQuestionIndex > 0 && (
                          <button
                            id="backbtn"
                            className="backbtn"
                            onClick={previousQuestion}
                          >
                            <i className="fa-solid fa-chevron-left"></i>{" "}
                            Previous
                          </button>
                        )}
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="nextbtn"
                            className="nextbtn"
                            onClick={nextQuestion}
                          >
                            Next <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}
                        {/* {currentQuestionIndex === questionData.length - 1 && ( */}
                        {currentQuestionIndex > 0 && (
                          <button
                            id="submitbtn"
                            className="submitbtn"
                            onClick={submitFun}
                          >
                            <i className="fa-solid fa-check"></i> Submit
                          </button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="card-footer text-center">
                {/* <div className="bg-primary">
                  <p>Attempted</p>
                  {JSON.stringify(attemptedQuestionsId)}
                </div>
                <div className="bg-success">
                  <p>Skipped</p>
                  {JSON.stringify(skipQuestionId)}
                </div>
                <div className="bg-secondary">
                  <p>QuestionNo=Ans</p>
                </div> */}

                <p className="d-none d-md-block">
                  <span className="badge bg-primary m-1  h4">
                    Total Question : {questionData.length}{" "}
                  </span>
                  <span className="badge bg-success m-1  h4">
                    Attempt Question : {uniqueLengthAttemptedQuestion}
                  </span>
                  <span className="badge bg-warning text-dark m-1  h4">
                    Un Attempt Question :{" "}
                    {questionData.length -
                      (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                  </span>
                  <span className="badge bg-danger m-1  h4">
                    Skip Question : {uniqueLengthSkipQuestion}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamStatic;
