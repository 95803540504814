import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Optional: for blur effect

const LazyImage = ({ src, placeholder, errorImage, alt, width, height,className, ...props }) => {
  return (
    <LazyLoadImage
      alt={alt}
      effect="blur" // Optional: adds a blur effect
      src={src} // Use normal src for the image
      placeholderSrc={placeholder} // Placeholder image
      onError={(e) => {
        e.target.onerror = null; // Prevents infinite loop in case error image fails
        e.target.src = errorImage; // Set error image on error
      }}
      style={{ width: `${width}px`, height: `${height}px` }} 
      className={className} 
      {...props}
    />
  );
};

export default LazyImage;