import React, { useState, useEffect } from "react";
import axios from 'axios';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import { useSelector } from "react-redux";
import ApiLoading from '../components/ApiLoading';
import ApiError from '../components/ApiError';
import "datatables.net-dt/css/dataTables.dataTables.css";

DataTable.use(DT);

const ExamHistory = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = useSelector((state) => state.auth.user.token);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        fetchPurchase();
    }, []);

    const fetchPurchase = async () => {
        try {
            const response = await axios.get(`${apiUrl}/exam-history`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (response.data.success) {
                const transformedData = response.data.data.map((item, index) => [
                    index + 1,
                    item.set_name, 
                    item.exam_for, 
                    item.totalQuestions, 
                    item.attemptedQuestions, 
                    item.skippedQuestions, 
                    item.correctAnswers, 
                    item.incorrectAnswers, 
                    item.is_negative, 
                    item.negative_mark, 
                    item.is_complete, 
                    `${item.start_datetime} || ${item.end_datetime}`, 
                ]);
                setTableData(transformedData);
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <ApiLoading />;
    if (error) return <ApiError message={error} />;

    return (
        <div className="container mt-3">
            <p className="text-center text-primary fw-bold"><i>Exam history</i></p>
            <div className="card table-responsive-md p-1" style={{fontSize:12}}>
                <DataTable data={tableData} className=" table table-striped table-hover table-bordered p-1 dispaly bg-light">
                    <thead className="bg-secondary">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Total Questions</th>
                            <th>Attempted Questions</th>
                            <th>Skipped Questions</th>
                            <th>Correct Answers</th>
                            <th>Incorrect Answers</th>
                            <th>Is Negative Mark</th>
                            <th>Per Qes. Negative Mark</th>
                            <th>Completed</th>
                            <th>Start End</th>
                        </tr>
                    </thead>
                </DataTable>
            </div>
        </div>
    );
}

export default ExamHistory;
