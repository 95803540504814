import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import axios from 'axios';
import { toast } from 'react-toastify';

const ResetPasswordPage = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const location = useLocation(); // To get the query parameters (e.g., the token)
    const navigate = useNavigate(); // Use useNavigate for navigation

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token'); // Get the reset token from URL

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate passwords match
        if (password !== confirmPassword) {
            setErrorMessage("Passwords don't match.");
            return;
        }

        // Clear previous error message
        setErrorMessage('');
        setLoading(true);
        const toastId = toast.loading("Processing...", {
            style: { backgroundColor: '#3498db', color: '#fff' },
        });

        try {
            const data = {
                email: email,
                password: password,
                password_confirmation: confirmPassword,
                token: token,
               };
            await axios.post(`${apiUrl}/password-reset`, data).then((response) => {
                setLoading(false);
                setEmail('');
                setConfirmPassword('');setPassword('');
                if (response.data.success === true) {
                    toast.update(toastId, { render: `${response.data.message}`, type: "success", isLoading: false, autoClose: 3000 });
                } else {
                    toast.update(toastId, { render: `${response.data.message}`, type: "warning", isLoading: false, autoClose: 3000 });
                }
            });    
        } catch (error) {
            toast.update(toastId, { render: `${error}`, type: "error", isLoading: false, autoClose: 3000 });
            setLoading(false);
        }
    };

    return (
        <div className="reset-password-page">
            <h2 className="m-2 text-center">Reset Your Password</h2>
            { token ? 

            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4  card p-2 mt-3">
                        <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input
                                type="email"
                                id="email"  readOnly={loading} 
                                value={email}
                                className="form-control m-1"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                id="password"
                                min="6"  readOnly={loading} 
                                className="form-control m-1"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Enter your new password"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="confirm-password">Confirm Password</label>
                            <input
                                type="password"
                                id="confirm-password"
                                className="form-control m-1"
                                min="6"
                                readOnly={loading} 
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your new password"
                                required
                            />
                        </div>

                        <button type="submit" className="reset-button" disabled={loading} className="btn btn-success my-2">
                            {loading ? 'Resetting...' : 'Reset Password'}
                        </button>
                    </form>
                    {errorMessage && <
                        div className="error-message text-danger text-center">{errorMessage}</div>
                    }
                    </div>
                </div>
            </div>
           : <p className="m-5 bg-light text-danger h4 text-center p-2 rounded">Token is expired or not found</p> }
        </div>
    );
};

export default ResetPasswordPage;
