import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    //window.scrollTo(0, 0);
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth', // Enables smooth scrolling
      });
  }, [pathname]);

  return null; // This component doesn't render anything
};

export default ScrollToTop;
