import React,{useState} from "react";
import axios from "axios";
// import Loader from "../components/Loader";
import { toast } from 'react-toastify';
import SubscribeUs from "../components/SubscribeUs";
import { useSelector } from "react-redux";

export default function ContactUs() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [message,setMessage] = useState('');
    // const [loading, setLoading] = useState(false);


    const handleSubmit = async (e) =>{
        e.preventDefault();
        //const toastId = toast.loading("Processing...");
        document.getElementById('sbmtbtn').disabled = true;
        const toastId = toast.loading("Processing...", {
            style: { backgroundColor: '#3498db', color: '#fff' }, // Custom background and text color
          });
        try {
            //setLoading(true);
            const data = {
                name,email,phone,message
              };
          await axios.post(`${apiUrl}/contactUs`,data).then((response)=>{
               // console.log(response.data)
               document.getElementById('sbmtbtn').disabled = false;
                if(response.data.success===true){
                    setName('');setEmail('');setPhone('');setMessage('');
                    //toast.success("Thank you for contact we will contact you soon");
                    toast.update(toastId, { render: "Thank you for contact we will contact you soon!", type: "success", isLoading: false, autoClose: 3000 });
                }else{
                    toast.update(toastId, { render: `Failed__${response.data.message}`, type: "error", isLoading: false, autoClose: 3000 });
                }
            })
        } catch (error) {
            toast.update(toastId, { render: `Failed_Error__${error}`, type: "error", isLoading: false, autoClose: 3000 });
            document.getElementById('sbmtbtn').disabled = false;
        }
    }

    return (
        <div className={`container mb-5 mt-2`}>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="contact-us-container">
                      <div className={`card p-2 bg-${mode} text-${modeText}`}>
                        <h1>Contact Us</h1>
                        <p>If you have any questions, feedback, or inquiries, please feel free to contact us using the form below or via email at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>
                      </div>
                        <div className={`card p-2 mt-1 bg-${mode} text-${modeText}`}>
                            <form className="contact-form" id="contactForm" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="name">Name:</label>
                                    <input type="text" className="form-control" id="name" name="name" value={name} onChange={(e)=>setName(e.target.value)} required />
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="email">Email:</label>
                                            <input type="email" value={email} className="form-control" id="email" name="email" onChange={(e)=>setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="phone">Phone:</label>
                                            <input type="text" className="form-control" id="phone" value={phone} name="phone"  maxLength="10" onChange={(e)=>setPhone(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message:</label>
                                    <textarea className="form-control" id="message" name="message" rows="5" onChange={(e)=>setMessage(e.target.value)} value={message} required></textarea>
                                </div>
                                <button type="submit" id="sbmtbtn" className="btn btn-primary mt-1">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <SubscribeUs />

        </div>
    );
}
