import { useSelector } from "react-redux";

export default function QuizCard() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);
    return <div>
        <section className={`bg-light pt-2 pb-2 shadow-sm  bg-${mode} text-${modeText}`}>
            <div className="container-fluid">
                <div className="row pt-5">
                    <div className="col-12">
                        <h3 className="text-uppercase mb-4 text-center">India's Comprehensive Online Test Series Platform</h3>
                        <p className=" border-bottom text-center">Maximize your exam readiness with our comprehensive Test Series tailored for Banking, SSC, RRB, and all other Government Exams.</p>
                    </div>
                </div>
                <div className="row">

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/CompetitiveExams.webp" height="150" className="card-img-top" alt="Card 1" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">Competitive Exams</h5>
                                <p className="card-text mb-4">Prepare for top exams like JEE, NEET, and UPSC with mock tests aligned to the latest patterns and syllabus.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/board.webp"  height="150" className="card-img-top" alt="Card 2" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">Board Exams Preparation</h5>
                                <p className="card-text mb-4">Ace your CBSE, ICSE, or state board exams with mock tests, revision materials, and practice questions tailored to your curriculum.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/EntranceExams.webp" height="150"  className="card-img-top" alt="Card 3" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">Entrance Exams for Professional Courses</h5>
                                <p className="card-text mb-4">Prepare for CLAT, CAT, and GATE with practice tests and resources to help you secure admission to top programs.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/gov.webp"  height="150"  className="card-img-top" alt="Card 3" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">State-Level Recruitment Exams</h5>
                                <p className="card-text mb-4">Prepare for state-specific govt. exams with tailored practice tests to stay competitive in the local job market.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/skill.webp"  height="150"  className="card-img-top" alt="Card 3" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">Skill Development and Certifications</h5>
                                <p className="card-text mb-4">Boost your career with mock tests for certifications like PMP, AWS, and Six Sigma, plus study resources.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-2 mb-3 d-flex cardN align-items-stretch">
                        <div className="card">
                            <img src="image/language.webp"  height="150"  className="card-img-top" alt="Card 3" />
                            <div className={`card-body d-flex flex-column bg-${mode} text-${modeText}`}>
                                <h5 className="card-title fw-bold">Language Proficiency Tests</h5>
                                <p className="card-text mb-4">Prepare for language proficiency tests with targeted practice to enhance your skills.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}