// PaymentBlossomModal.js
import React from 'react';
import jsPDF from 'jspdf';
import './paymentBlossomModal.css'; // Ensure you have this CSS file for styles

const PaymentBlossomModal = ({ isOpen, onClose, receipt }) => {
    if (!isOpen) return null;

    const downloadReceipt = () => {
        const receiptContent = `
      Payment Receipt
      --------------------
      Message: ${receipt.message}
      Order ID: ${receipt.order_id}
      Payment ID: ${receipt.payment_id}
      Amount: INR ${receipt.amount}
      Status: ${receipt.status}
    `;

        const blob = new Blob([receiptContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'payment_receipt.txt';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url); // Clean up
    };

    //   const downloadReceiptPDF = () => {
    //     const doc = new jsPDF();
    //     doc.setFontSize(12);
    //     doc.text("Payment Receipt", 20, 20);
    //     doc.text(`Message: ${receipt.message}`, 20, 30);
    //     doc.text(`Order ID: ${receipt.order_id}`, 20, 40);
    //     doc.text(`Payment ID: ${receipt.payment_id}`, 20, 50);
    //     doc.text(`Amount: $${receipt.amount}`, 20, 60);
    //     doc.text(`Status: ${receipt.status}`, 20, 70);
    //     doc.save("payment_receipt.pdf");
    //   };

    const downloadReceiptPDF = () => {
        const doc = new jsPDF();

        // Set Company Name
        doc.setFontSize(20);
        doc.setFont("Helvetica", "bold");
        const companyName = "Your Company Name";
        const textWidth = doc.getTextWidth(companyName);
        const xPosition = (doc.internal.pageSize.width - textWidth) / 2;
        doc.text(companyName, xPosition, 20); // Centered at the top

        // Set Receipt No and Date/Time
        const receiptNo = `Receipt No: ${receipt.receipt_no}`;
        const dateTime = new Date().toLocaleString(); // Format as needed
        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        doc.text(receiptNo, 15, 40); // Left side
        doc.text(dateTime, doc.internal.pageSize.width - 15, 40, { align: 'right' }); // Right side

        // Header
        doc.setFontSize(20);
        doc.setFont("Helvetica", "bold");
        doc.text("~ Payment Receipt ~", 15, 60);

        // Payment Details
        doc.setFontSize(14);
        doc.text(`Message: ${receipt.message}`, 15, 80);
        doc.text(`Order ID: ${receipt.order_id}`, 15, 90);
        doc.text(`Payment ID: ${receipt.payment_id}`, 15, 100);
        doc.text(`Amount: INR ${receipt.amount}`, 15, 110);
        doc.text(`Status: ${receipt.status}`, 15, 120);

        // Payer Information
        doc.setFontSize(16);
        doc.setFont("Helvetica", "bold");
        doc.text("Payer Details", 15, 140);
        doc.setFont("Helvetica", "normal");
        doc.text("Name: John Doe", 15, 150); // Replace with actual data
        doc.text("Email: john.doe@example.com", 15, 160); // Replace with actual data

        // Payment Gateway
        doc.setFontSize(16);
        doc.setFont("Helvetica", "bold");
        doc.text("Payment Gateway", 15, 180);
        doc.setFont("Helvetica", "normal");
        doc.text("Gateway: RazorPay", 15, 190); // Replace with actual data
        doc.text("Transaction ID: XYZ123456", 15, 200); // Replace with actual data

        // Footer
        doc.setFontSize(10);
        doc.setFont("Helvetica", "italic");
        doc.text("**This is a system-generated document.**", 15, 220);

        // Save PDF
        doc.save("payment_receipt.pdf");
    };





    return (
        <div className="payment-blossom-overlay">
            <div className="payment-blossom-content">
                <h2 className="payment-blossom-title">🌸 Payment Receipt 🌸</h2>
                <div className="payment-blossom-body">
                    <p><strong>Message:</strong> {receipt.message}</p>
                    <p><strong>Order ID:</strong> {receipt.order_id}</p>
                    <p><strong>Payment ID:</strong> {receipt.payment_id}</p>
                    <p><strong>Amount:</strong> Rs. {receipt.amount}</p>
                    <p><strong>Status:</strong> {receipt.status}</p>
                </div>
                <button className="payment-blossom-close-button" onClick={onClose}>Close</button>
                <button
                    className="payment-blossom-download-button"
                    onClick={downloadReceipt}>
                    Download Text Receipt
        </button>
                <button
                    className="payment-blossom-download-button"
                    onClick={downloadReceiptPDF}>
                    Download PDF Receipt
        </button>
            </div>
        </div>
    );
};

export default PaymentBlossomModal;
