import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import { useSelector } from "react-redux";

export default function Footer() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return (
        <footer className={`mt-3 bg-${mode} text-${modeText} `}>
          <div className="footer-info m-auto">
          <div className="container-fluid">
           <div className="row">
                <div className="col-md-2">
                    <div className="company-info">
                        <p>
                        <img
                            src="/logo.png"
                            alt=""
                            width="55"
                            height="30"
                            className="d-inline-block align-text-top"
                        />
                        {/* <span>Upgrade</span> */}
                        </p>
                        {/* <p>123 Mock Test Street, City, Country</p> */}
                    </div>
                </div>
                <div className="col-md-8 text-center">
                    <div className="footer-links">
                        <Link to={'/privacy-policy'}>Privacy Policy</Link>
                        <Link to={'/terms-and-condition'}>Terms & Condition</Link>
                        <Link to={'/user-policy'}>User Policy</Link>
                        <Link to={'/refund-and-cancellation'}>Refund & Cancellation</Link>
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="social-links">
                        <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-square-facebook text-primary"></i></a>
                        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-twitter text-info"></i> </a>
                        <a href="https://www.linkedin.com/company/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-square-twitter"></i></a>
                        <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-square-instagram  text-danger"></i></a>
                    </div>
                </div>
           </div>
           </div>
          </div>
        </footer>
    );
}



               



