import React, { useState, useEffect } from "react";
import "./exam.css";
import { useNavigate,useLocation } from "react-router-dom";
import queryString from 'query-string';
import { NotificationManager } from "react-notifications";
// import questions from "../questions.json";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import axios from "axios";
import Loader from "./../components/Loader";
import { useSelector } from 'react-redux'
import LazyImage from '../components/LazyImage';

function NewMocktest() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const currentParams = queryString.parse(location.search); 
  // const { id }  = currentParams;
  const { id, agree } = location.state || {};
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [examtime, setExamtime] = useState(0);
  const [timerBlinking, setTimerBlinking] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); //for checking current option
  const [attemptedQuestionsId, setAttemptedQuestionsId] = useState([]);
  const [skipQuestionId, setSkipQuestionId] = useState([]);
  const [markedQuestionId, setMarkedQuestionId] = useState([]);
  const [saveMarkForReviewIdQId, setSaveMarkForReviewQId] = useState([]);
  const [visitedQId, setVisitedQId] = useState([1]);
  const [isLoading, setIsLoading] = useState(true);
  const [userResponses, setUserResponses] = useState({}); // For storing result
  const [examStartId, setExamStartId] = useState('');

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const serverImagePath = process.env.REACT_APP_CATEGORY_IMAGE_BASE_URL;
  const serverQuestionPath = process.env.REACT_APP_QUESTION_IMAGE_BASE_URL;
  const serverOptionPath = process.env.REACT_APP_OPTION_IMAGE_BASE_URL;

  const handleUserResponse = (questionId, selectedOption, DBquesionid) => {
    //set active selected option
    setSelectedOption(selectedOption);
    //marked question as attempt
    attemptQuestion(questionId);

    setUserResponses((prevResponses) => ({
      ...prevResponses,
      [DBquesionid]: selectedOption,
    }));
  };

  //Add in attempt question array
  const attemptQuestion = (questionId) => {
    setAttemptedQuestionsId((attemptedQuestionsId) => {
      // Check if the questionId already exists in the array
      if (!attemptedQuestionsId.includes(questionId)) {
        // If it doesn't exist, insert the questionId
        return [...attemptedQuestionsId, questionId];
      }
      // If it already exists, return the current array unchanged
      return attemptedQuestionsId;
    });
  };

  //getting count of attempt question
  const uniqueLengthAttemptedQuestion = [...new Set(attemptedQuestionsId)]
    .length;

  //check skip question and add in skip question array
  const skipQuestion = (questionId) => {
    if (attemptedQuestionsId.includes(questionId)) {
      //skip question attempt later then it remove from skip and add in attempt
      attemptQuestion(questionId);

      let indexToRemove;
      while ((indexToRemove = skipQuestionId.indexOf(questionId)) !== -1) {
        skipQuestionId.splice(indexToRemove, 1);
      }
    } else {
      //console.log(questionId);
      //add in skip list
      setSkipQuestionId((skipQuestionId) => [...skipQuestionId, questionId]);
    }
  };

  //getting count of Skip question
  const uniqueLengthSkipQuestion = [...new Set(skipQuestionId)].length;

  // Next button handle
  const nextQuestion = () => {
    //setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    loadNextQuestion();
    setSelectedOption("");
    //skip question section
    //skipQuestion(currentQuestionIndex + 1);
  };

  // Previous button handle
  const previousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    setSelectedOption("");
  };

  //skip button handle
  const skipbtnhandle = () => {
    setSelectedOption("");
    if (!skipQuestionId.includes(currentQuestionIndex + 1)) {
      skipQuestion(currentQuestionIndex + 1);
    }
    //setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    loadNextQuestion();
  };

  //mark for review button handle
  const reviewHandle = () => {
    setSelectedOption("");
    if (!markedQuestionId.includes(currentQuestionIndex + 1)) {
      setMarkedQuestionId((markedQuestionId) => [
        ...markedQuestionId,
        currentQuestionIndex + 1,
      ]);
    }
    // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    loadNextQuestion();
  };

  //save and mark for review button handle
  const savaAndReviewHandle = () => {
    setSelectedOption("");
    attemptQuestion(currentQuestionIndex + 1);
    if (!saveMarkForReviewIdQId.includes(currentQuestionIndex + 1)) {
      setSaveMarkForReviewQId((saveMarkForReviewIdQId) => [
        ...saveMarkForReviewIdQId,
        currentQuestionIndex + 1,
      ]);
    }
    // setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    loadNextQuestion();
  };

  // Submit button handle
  const submitFun = () => {
    calculate();
    NotificationManager.success("Calculating result...", "Exam end");
  };

  //Timer end
  const timeEndFunction = () => {
    calculate();
    NotificationManager.warning("Calculating result...", "Exam time end");
  };

  //const load next question
  const loadNextQuestion = () => {
    if (!visitedQId.includes(currentQuestionIndex + 1)) {
      setVisitedQId((visitedQId) => [...visitedQId, currentQuestionIndex + 1]);
    }
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  //check previously answered or not
  const checkPreviouslyAnswered = (qno) => {
    if (userResponses[questionData[qno].id]) {
      let pselectedoption = userResponses[questionData[qno].id];
      // console.log(pselectedoption);
      setSelectedOption(pselectedoption);
    } else {
      setSelectedOption("");
    }
  };

  //calculation
  const calculate = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .post(
        `${apiUrl}/calculateResult`,
        { userResponses , examStartId , attempted : attemptedQuestionsId.length ,skipped : skipQuestionId.length },
        // {filteredResponses},
        config
      )
      .then((response) => {
        //console.log(response.data);
        if (response.data.success === true) {
          const resultData = {
            totalQuestions: questionData.length,
            attemptedQuestions: attemptedQuestionsId.length,
            skippedQuestions: skipQuestionId.length,
            correctAnswers: response.data.result.correctAnswers,
            incorrectAnswers: response.data.result.incorrectAnswers,
          };
          navigate("/examResult", { state: resultData });
        } else {
          alert("Failed to submit");
        }
      })
      .catch((error) => {
        // Handle error if needed
        console.error(error);
      });
  };

  //prevent reload back forward exit
  useEffect(() => {
    const preventNavigation = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    window.addEventListener("popstate", preventNavigation);
    window.onbeforeunload = () => ""; // For handling refresh

    return () => {
      window.removeEventListener("popstate", preventNavigation);
      window.onbeforeunload = null;
    };
  }, []);

  //set current question
  const setCurrentQuestion = (qno) => {
    setCurrentQuestionIndex(qno);
    checkPreviouslyAnswered(qno);
  };

  // Load questions
  useEffect(() => {
    // setQuestionData(questions);
    const fetchQuestion = async () => {
      const token = localStorage.getItem("accessToken"); // Replace 'YOUR_BEARER_TOKEN' with your actual token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        await axios
          .get(`${apiUrl}/examQuestions/${id}`, config)
          .then((res) => {
            if(res.data.success===true){
              setQuestionData(res.data.data);
              if (res.data.setData && res.data.setData.exam_time) {
                setExamtime(res.data.setData.exam_time * 60);
                setExamStartId(res.data.examStartId);
              }
              setIsLoading(false);
            }
          });
      } catch (error) {
        console.error("Error fetching questions:", error);
        alert("Server busy"+error);
      }
    };
    fetchQuestion();
  }, []);

  //qno click handle
  const handleClickQno = (index) => {
    setSelectedOption("");
    setCurrentQuestion(index);
    if (!visitedQId.includes(index + 1)) {
      setVisitedQId((visitedQId) => [...visitedQId, index + 1]);
    }
  };

  //uncheck answer button handle
  const uncheckAnswerBtnHandle = (dbqid) => {
    //console.log("load", dbqid);
    //remove from user responce
    setUserResponses((prevResponses) => {
      const { [dbqid]: _, ...rest } = prevResponses;
      return rest;
    });
    setSelectedOption("");
    console.log("currnt", currentQuestionIndex);
    //remove from attempt
    setAttemptedQuestionsId((prev) =>
      prev.filter((id) => id !== currentQuestionIndex + 1)
    );
    //removed from marked question
    setMarkedQuestionId((prev) =>
      prev.filter((id) => id !== currentQuestionIndex + 1)
    );
    //removed from save & mark for review
    setSaveMarkForReviewQId((prev) =>
      prev.filter((id) => id !== currentQuestionIndex + 1)
    );
  };

  return isLoading ? (
    <Loader /> 
  ) : (
    <div className="container-fluid">
    {`Mocktest : ${id}`} || {examStartId}
      <div className="container-fluid mt-md-5 mt-1">
        <div className="row">
          <div
            className={`col-md-2 text-center h-25 d-inline-block shadow-lg p-md-5 p-1 mb-1 rounded bg-${mode} text-${modeText}`}
          >
            <div className="row">
              <div className="col-md-12 col-5 ">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Remaining time
                </p>
                <CountdownCircleTimer
                  isPlaying
                  duration={examtime}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  size={150}
                  colorsTime={[7, 5, 2, 0]}
                  isGrowing={false}
                  onComplete={timeEndFunction}
                >
                  {({ remainingTime }) => {
                    const hours = Math.floor(remainingTime / 3600);
                    const minutes = Math.floor((remainingTime % 3600) / 60);
                    const seconds = remainingTime % 60;

                    // if (remainingTime < 60) {
                    //   setTimerBlinking(true);
                    // } else {
                    //   setTimerBlinking(false);
                    // }

                    return (
                      <div
                        className={` rounded m-1 ${
                          timerBlinking ? "blink" : ""
                        }`}
                      >
                        <p className="text-center fw-bold">
                          ⏲ <br />
                          <span>
                            {`${hours} hour `} <br />
                            {`${minutes} minute `} <br />
                            {`${seconds} second`}
                          </span>
                        </p>
                      </div>
                    );
                  }}
                </CountdownCircleTimer>
              </div>
              <div className="col-7 d-block d-md-none m-auto shadow-sm p-1">
                <p style={{ color: "tomato", fontFamily: "monospace" }}>
                  Summery
                </p>
                <p className="badge bg-primary m-1 h4">
                  Total Question : {questionData.length}
                </p>
                <p className="badge bg-success m-1 h4">
                  Attempt Question : {uniqueLengthAttemptedQuestion}
                </p>
                <p className="badge bg-warning m-1 text-dark h4">
                  UnAttempt Question :
                  {questionData.length -
                    (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                </p>
                <p className="badge bg-danger m-1 h4">
                  Skip Question : {uniqueLengthSkipQuestion}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div
              className={`card shadow-lg  mb-2 rounded  bg-${mode} text-${modeText}`}
            >
              <div className="card-body">
                <div className="question-section">
                  {questionData.length > 0 ? (
                    <>
                      <div className="question">
                        {currentQuestionIndex + 1}){" "}
                        {/* //load question start */}
                        {questionData[currentQuestionIndex].question}

                        {/* //load question end */}
                        {/* //load question details start */}
                        <div className="questionDescription text-center">
                          {questionData[currentQuestionIndex]
                            .question_desc_isImage === 1 ? (
                            <LazyImage
                            src={`${
                              serverQuestionPath +
                              questionData[currentQuestionIndex].question_desc
                            }`}
                            placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} 
                            errorImage={`${process.env.PUBLIC_URL}/myicons/no-image.png`}
                            alt={`Question_${currentQuestionIndex}`}
                            width={'auto'}
                            height={'auto'} 
                            className="img-fluid qdescimage"
                          />

                          ) : (
                            <p>
                              {questionData[currentQuestionIndex].question_desc}
                            </p>
                          )}
                        </div>
                        {/* //load question details end */}
                      </div>
                      {/* ------------Option section start ------------------  */}
                      <div className="container-fluid question-container">
                        <div className="row option-section">
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "A" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="A"
                              // checked={selectedOption === "A"}
                              checked={
                                userResponses[
                                  questionData[currentQuestionIndex].id
                                ] === "A"
                              }
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "A",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />
                            &nbsp; i){" "}
                            {questionData[currentQuestionIndex]
                              .optionA_isImage === 1 ? (
                              <LazyImage
                                src={`${
                                  serverOptionPath +
                                  questionData[currentQuestionIndex].optionA
                                }`}
                                placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} 
                                errorImage={`${process.env.PUBLIC_URL}/myicons/error.png`}
                                alt={`Question_OPTION_A_${currentQuestionIndex}`}
                                width={'auto'}
                                height={'auto'} 
                                className="img-fluid h-25 d-inline-block qdescimage"
                            />
                            ) : (
                              <span>
                                {questionData[currentQuestionIndex].optionA}
                              </span>
                            )}
                          </label>
                          <label
                            className={`option2 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "B" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="B"
                              // checked={selectedOption === "B"}
                              checked={
                                userResponses[
                                  questionData[currentQuestionIndex].id
                                ] === "B"
                              }
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "B",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />
                            &nbsp; ii)
                            {questionData[currentQuestionIndex]
                              .optionB_isImage === 1 ? (
                              <LazyImage
                                src={`${
                                  serverOptionPath +
                                  questionData[currentQuestionIndex].optionB
                                }`}
                                placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} 
                                errorImage={`${process.env.PUBLIC_URL}/myicons/error.png`}
                                alt={`Question_OPTION_B_${currentQuestionIndex}`}
                                width={'auto'}
                                height={'auto'} 
                                className="img-fluid h-25 d-inline-block qdescimage"
                            />
                            ) : (
                              <span>
                                {questionData[currentQuestionIndex].optionB}
                              </span>
                            )}
                          </label>
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "C" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="C"
                              // checked={selectedOption === "C"}
                              checked={
                                userResponses[
                                  questionData[currentQuestionIndex].id
                                ] === "C"
                              }
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "C",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />{" "}
                            &nbsp; iii){" "}
                            {questionData[currentQuestionIndex]
                              .optionC_isImage === 1 ? (
                              <LazyImage
                                src={`${
                                  serverOptionPath +
                                  questionData[currentQuestionIndex].optionC
                                }`}
                                placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} 
                                errorImage={`${process.env.PUBLIC_URL}/myicons/error.png`}
                                alt={`Question_OPTION_C_${currentQuestionIndex}`}
                                width={'auto'}
                                height={'auto'} 
                                className="img-fluid h-25 d-inline-block qdescimage"
                            />
                            ) : (
                              <span>
                                {questionData[currentQuestionIndex].optionC}
                              </span>
                            )}
                          </label>
                          <label
                            className={`option1 options col-md-6 mt-1 mb-1 ${
                              selectedOption === "D" ? "active" : ""
                            }`}
                          >
                            <input
                              type="radio"
                              name="option"
                              value="D"
                              // checked={selectedOption === "D"}
                              checked={
                                userResponses[
                                  questionData[currentQuestionIndex].id
                                ] === "D"
                              }
                              onChange={() =>
                                handleUserResponse(
                                  currentQuestionIndex + 1,
                                  "D",
                                  questionData[currentQuestionIndex].id
                                )
                              }
                            />
                            &nbsp; iv){" "}
                            {questionData[currentQuestionIndex]
                              .optionD_isImage === 1 ? (
                              <LazyImage
                                src={`${
                                  serverOptionPath +
                                  questionData[currentQuestionIndex].optionD
                                }`}
                                placeholder={`${process.env.PUBLIC_URL}/myicons/loading.png`} 
                                errorImage={`${process.env.PUBLIC_URL}/myicons/error.png`}
                                alt={`Question_OPTION_D_${currentQuestionIndex}`}
                                width={'auto'}
                                height={'auto'} 
                                className="img-fluid h-25 d-inline-block qdescimage"
                            />
                            ) : (
                              <span>
                                {questionData[currentQuestionIndex].optionD}
                              </span>
                            )}
                          </label>
                        </div>
                      </div>

                      {/* ------------Option section end ------------------  */}
                      <div className="qbuttons text-center">
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="review"
                            className="mybtn reviewbtn"
                            onClick={reviewHandle}
                            disabled={selectedOption === ""}
                          >
                            Mark for review & next &nbsp;
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="savereview"
                            className="mybtn savereview"
                            onClick={savaAndReviewHandle}
                            disabled={selectedOption === ""}
                          >
                            Save & mark for review &nbsp;
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="skip"
                            className="mybtn skipbtn"
                            onClick={skipbtnhandle}
                            disabled={selectedOption !== ""}
                          >
                            Skip &nbsp;
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}
                        {currentQuestionIndex < questionData.length - 1 && (
                          <button
                            id="nextbtn"
                            className="mybtn nextbtn"
                            onClick={nextQuestion}
                            disabled={selectedOption === ""}
                          >
                            Save & Next{" "}
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        )}

                        <button
                          id="resetThisbtn"
                          className="mybtn"
                          onClick={() =>
                            uncheckAnswerBtnHandle(
                              questionData[currentQuestionIndex].id
                            )
                          }
                        >
                          Uncheck answer
                        </button>

                        <button
                          id="submitbtn"
                          className="mybtn submitbtn"
                          onClick={submitFun}
                        >
                          <i className="fa-solid fa-check"></i> Submit
                        </button>
                      </div>
                    </>
                  )
                  :<div className="text-center">
                    <p className="text-danger fw-bold">Currenty No question Available</p>
                    <img src="myicons/emptyFolder.png" className="img-fluid" width="100" height="100" alt="empty" />
                    <br />
                    <p className="badge bg-primary" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i> &nbsp; Go back</p>
                  </div>
                }
                </div>
              </div>
              <div className="card-footer d-none text-center">
                <span className="allArrays">
                  <div className="bg-primary">
                    <p>Attempted</p>
                    {JSON.stringify(attemptedQuestionsId)}
                  </div>
                  <div className="bg-success">
                    <p>Skipped</p>
                    {JSON.stringify(skipQuestionId)}
                  </div>
                  <div className="bg-secondary">
                    <p>save and mark for review</p>
                    {JSON.stringify(saveMarkForReviewIdQId)}
                  </div>
                  <div className="bg-danger">
                    <p>marked for review</p>
                    {JSON.stringify(markedQuestionId)}
                  </div>
                  <div className="bg-info">
                    <p>Visited question</p>
                    {JSON.stringify(visitedQId)}
                  </div>
                  <div className="bg-primary">
                    <p>Question id & Answer</p>
                    {JSON.stringify(userResponses)}
                  </div>
                </span>
                <p className="d-none d-md-block">
                  <span className="badge bg-primary m-1  h4">
                    Total Question : {questionData.length}{" "}
                  </span>
                  <span className="badge bg-success m-1  h4">
                    Attempt Question : {uniqueLengthAttemptedQuestion}
                  </span>
                  <span className="badge bg-warning text-dark m-1  h4">
                    Un Attempt Question :{" "}
                    {questionData.length -
                      (uniqueLengthAttemptedQuestion + skipQuestionId.length)}
                  </span>
                  <span className="badge bg-danger m-1  h4">
                    Skip Question : {uniqueLengthSkipQuestion}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div
            className={`col-md-2 shadow-lg  mb-1 rounded questionNoSection bg-${mode} text-${modeText}`}
          >
            <p
              style={{ color: "tomato", fontFamily: "monospace" }}
              className="text-center m-2"
            >
              Question numbers
            </p>
            <div className="row gap-2 m-3 m-sm-0">
              {questionData.map((question, index) => (
                <div
                  key={index}
                  // className="col-md-2 col-2 p-md-2 p-1 questionNo text-center"
                  className={`col-md-2 col-2 p-md-2 p-1 questionNo text-center ${
                    index === currentQuestionIndex
                      ? "currentQuestion"
                      : saveMarkForReviewIdQId.includes(question.slno)
                      ? "markForReview"
                      : skipQuestionId.includes(question.slno)
                      ? "skipped"
                      : markedQuestionId.includes(question.slno)
                      ? "marked"
                      : attemptedQuestionsId.includes(question.slno)
                      ? "attempted"
                      : visitedQId.includes(question.slno)
                      ? "visited"
                      : ""
                  }`}
                  onClick={() => handleClickQno(index)}
                >
                  {index + 1}
                </div>
              ))}
            </div>
            <p className="mt-5">Color meanings</p>
            <div className="colorDefination mt-3 border-success mb-3">
              <span><span className="colorDefinationShape notvisted"></span> Non visited</span>
              <br />
              <span><span className="colorDefinationShape visited"></span> Visited</span>
              <br />
              <span><span className="colorDefinationShape attempted"></span>Attempt</span>
              <br />
              <span><span className="colorDefinationShape skipped"></span> skip</span>
              <br />
              <span><span className="colorDefinationShape marked"></span> marked for review</span>
              <br />
              <span><span className="colorDefinationShape markForReview"></span>Save & marked for review</span>
              <br />
              <span><span className="colorDefinationShape currentQuestion"></span>Current question</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewMocktest;