import { useSelector } from "react-redux";

export default function UserPolicy() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return <div className={`container mt-4 card p-2 bg-${mode} text-${modeText} `}>
        <h4 className="text-center mb-3"><u>User Policy</u></h4>
        Welcome to Upgrade! This User Policy outlines the terms and guidelines for using our website and services. By accessing or using Upgrade, you agree to comply with this User Policy and all applicable laws and regulations. If you do not agree with any part of this policy, you may not use our website or services.
        <br />
        <br /><h5>User Conduct</h5>

        <p><b>When using Upgrade, you agree to:</b></p>

        <p>Abide by all applicable laws, regulations, and guidelines.</p>
        <p>Respect the rights and privacy of others.</p>
        <p>Use the website and services for lawful purposes only.</p>
        <p>Refrain from engaging in any activity that may disrupt, damage, or impair the functioning of Upgrade or interfere with other users' access to the website.</p>

        <br /><h5>Account Registration</h5>

        <p>To access certain features of Upgrade, you may need to create an account. When registering an account, you agree to provide accurate and complete information and to keep your account credentials confidential. You are responsible for all activities that occur under your account, and you must notify us immediately of any unauthorized use or security breach.</p>

        <br /><h5>Content Guidelines</h5>

        <p>You may contribute content to Upgrade, such as comments, reviews, or study materials. When posting content, you agree to:</p>

        <p>Ensure that your content is accurate, relevant, and appropriate.</p>
        <p>Respect intellectual property rights by not posting copyrighted material without permission.
Refrain from posting content that is defamatory, discriminatory, or violates the rights of others.</p>
        <p>Grant Upgrade a perpetual, irrevocable, royalty-free license to use, modify, adapt, publish, translate, and distribute your content in any form and for any purpose.</p>

        <br /><h5>Prohibited Activities</h5>

        <p><b>You are prohibited from engaging in the following activities on Upgrade:</b></p>

        <p>Impersonating any person or entity, or falsely claiming affiliation with Upgrade.</p>
        <p>Transmitting spam, viruses, or any other harmful code.</p>
        <p>Attempting to gain unauthorized access to other users' accounts or sensitive information.</p>
        <p>Engaging in any form of automated data collection or scraping without our prior consent.</p>
        <p>Violating any applicable laws or regulations.</p>

        <br /><h5>Termination of Access</h5>

        <p>Upgrade reserves the right to suspend or terminate your access to the website or services at any time, without prior notice or liability, for any reason, including if you violate this User Policy or engage in conduct that we deem inappropriate or harmful.</p>

        <br /><h5>Changes to This Policy</h5>

        <p>Upgrade may revise this User Policy from time to time. We will notify you of any changes by posting the new policy on this page. By continuing to use Upgrade after any revisions, you agree to be bound by the updated policy.</p>

        <br /><h5>Contact Us</h5>

        <p>If you have any questions or concerns about this User Policy, please contact us at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>
    </div>;
}