import React, { useState } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

const SubscribeUs = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    const handleSubscribe = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Processing...", {
            style: { backgroundColor: '#3498db', color: '#fff' },
        });

        try {
            setLoading(true);
            const data = {
               email:email
              };
            await axios.post(`${apiUrl}/subscriptionMail`, data).then((response) => {
                setLoading(false);
                if (response.data.success === true) {
                  setEmail('');
                    //toast.success("Thank you for contact we will contact you soon");
                    toast.update(toastId, { render: "Thank you for Subscribe Us!", type: "success", isLoading: false, autoClose: 3000 });
                } else {
                    toast.update(toastId, { render: `Failed__${response.data.message}`, type: "error", isLoading: false, autoClose: 3000 });
                }
            })
        } catch (error) {
            toast.update(toastId, { render: `Failed_Error__${error}`, type: "error", isLoading: false, autoClose: 3000 });
            setLoading(false);
        }
    }

    return <div className={`container mt-3 mb-3 card p-3 bg-${mode} text-${modeText}`}>
        <form method="post" onSubmit={handleSubscribe}>
            <div className="row text-center">
                <div className="col-md-3">
                    <span className="fw-bold"><i>Subscribe to our newsletters right now and get special offers</i></span>
                </div>
                <div className="col-md-1">
                    <img src="myicons/newsletter.png" alt="subs" width="47" height="50" className="img-fluid m-1" />
                </div>
                <div className="col-md-6">
                    <input type="email" name="email" className="form-control m-1"
                        value={email} onChange={(e) => setEmail(e.target.value)}
                        disabled={loading}
                        readOnly={loading} //If you want to prevent editing while loading
                        style={{ opacity: loading ? 0.5 : 1 }} //visually indicate loading
                        required />
                </div>
                <div className="col-md-2">
                    <button type="submit" disabled={loading} className="btn btn-success m-1" >Subscribe</button>
                </div>
            </div>
        </form>
    </div>;
}

export default SubscribeUs;