import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import ApiLoading from '../components/ApiLoading';
import ApiError from '../components/ApiError';


 const Profile = () => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const token = useSelector((state) => state.auth.user.token)
    const [profileData, setProfileData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    useEffect(() => {
        fetchProfile();
      }, []);

    const fetchProfile = async () =>{
        try {
            const response = await axios.get(`${apiUrl}/profile`, {
                headers: {
                  'Authorization': `Bearer ${token}`,
                },
              });
            setProfileData(response.data.data);
            console.log(response.data.data)
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }


    if (loading) return <ApiLoading message={'Loading data...'} />;
    if (error) return <ApiError  message={error} />;


    return <>
        <div className="container mt-3">
            <p className="text-center text-primary fw-bold"><i>Profile Details</i></p>
            <div className="row">
                <div className="col-md-4 ">
                    <div className={`card m-2 p-2 bg-${mode} text-${modeText}`}>
                         <p className="text-center"> <img src="myicons/user.png" alt="user" className="img-fluid " width="100" height="100" /> </p>
                        <p><span className="fw-bold">Name : </span> {profileData.name} </p>
                        <p><span className="fw-bold">Email : </span> {profileData.email} </p>
                        <p><span className="fw-bold">Mobile : </span> {profileData.mobile_number} </p>
                        <p><span className="fw-bold">Prvider : </span> {profileData.google_id ? 'Google' : 'Self' } </p>
                    </div>
                </div>
                <div className="col-md-8">
                    <div className={`card m-2 p-2 bg-${mode} text-${modeText}`}>
                        <p><span className="fw-bold">ROLE : </span> {profileData.role} </p>
                        <p><span className="fw-bold">GENDER  : </span> {profileData.gender} </p>
                        <p><span className="fw-bold">DOB  : </span> {profileData.dob} </p>
                        <p><span className="fw-bold">REGISTERED ON   : </span> {profileData.created_at} </p>
                        <p><span className="fw-bold">ADDRESS : </span> {profileData.address}  </p>
                    </div>
                </div>
            </div>
        </div>        
    </>;
}

export default Profile;