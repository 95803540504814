import { useSelector } from "react-redux";

export default function TermsAndConditions() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return <div className={`container mt-4 card p-2 bg-${mode} text-${modeText} `}>
        <h4 className="text-center mb-3"><u>Terms And Conditions</u></h4>
        Welcome to Upgrade! These Terms and Conditions outline the rules and regulations for the use of our website.
        <br />
        By accessing this website, we assume you accept these terms and conditions. Do not continue to use Upgrade if you do not agree to all of the terms and conditions stated on this page.
            <br /> <br />
        <h5>License to Use</h5>

        <p>Unless otherwise stated, Upgrade and/or its licensors own the intellectual property rights for all material on Upgrade. All intellectual property rights are reserved. You may view and/or print pages from https://www.upgrade.com for your own personal use subject to restrictions set in these terms and conditions.</p>

        <br /><h5>Restrictions</h5>

        <p><b>You are specifically restricted from all of the following:</b></p>

        <p>Publishing any website material in any other media.</p>
        <p>Selling, sublicensing, and/or otherwise commercializing any website material.</p>
        <p>Publicly performing and/or showing any website material.</p>
        <p>Using this website in any way that is or may be damaging to this website.</p>
        <p>Using this website in any way that impacts user access to this website.</p>
        <p>Using this website contrary to applicable laws and regulations, or in any way may </p><p>cause harm to the website, or to any person or business entity.</p>

        <br /><h5>User Account</h5>

        <p>If you create an account on Upgrade, you are responsible for maintaining the security of your account and for all activities that occur under the account. You must not describe or assign keywords to your account in a misleading or unlawful manner. We reserve the right to terminate accounts, remove or edit content at our sole discretion.</p>

        <br /><h5>Content</h5>

        <p>We reserve the right to monitor all content submitted to our website and to remove any content at our discretion.</p>

        <br /><h5>Disclaimer</h5>

        <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

        <p>limit or exclude our or your liability for death or personal injury;</p>
        <p>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</p>
        <p>limit any of our or your liabilities in any way that is not permitted under applicable law; or
exclude any of our or your liabilities that may not be excluded under applicable law.
Changes to Terms</p>

        <p>Upgrade reserves the right to revise these terms and conditions at any time as it sees fit. By using this website, you are expected to review such terms on a regular basis to ensure you understand all terms and conditions governing the use of this website.</p>

        <br /><h5>Contact Information</h5>

        <p>If you have any questions about these Terms and Conditions, please contact us at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>
    </div>;
}