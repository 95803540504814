import "./examCategoryCard.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const cimageBaseUrl = process.env.REACT_APP_CATEGORY_IMAGE_BASE_URL;


export function ExamCategoryCard({ data }) {
  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);

  return (
    <div className="container-fluid mt-3 p-4">
      <div className="row">
        {data.map((item, key) => (
            <div key={key} className={`ecccontainer col-6 col-md-2 cardN `}>
              <Link to={`/examDetails/${item.id}`} key={key}>
                <div className={`ecccontainerinner text-center shadow-lg p-3 rounded bg-${mode} text-${modeText}`}>
                  <img
                    src={cimageBaseUrl+item.image}
                    className="eccimage"
                    alt={`category ${key}`}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents looping
                      e.target.src = "/myicons/noimage.png"; // Fallback image on error
                    }}
                  />
                  <p className="pt-1 ecctitle">{item.name}</p>
                </div>
              </Link>
            </div>
        ))}
      </div>
    </div>
  );
}

export function ExamCategoryCardHorizontal({ data }) {
  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  return (
    <div className="container-fluid mt-3 p-4">
      <div className="row">
        {data.map((item, key) => (
          <div key={key} className="ecchcontainer col-12 col-md-3 cardN">
            <div className={`ecchcontainerinner shadow-lg rounded bg-${mode} text-${modeText}`}>
              <div className="row">
                <div className="col-2 m-auto pr-5 ecchimagebox">
                  <img
                    src={item.image}
                    // src={cimageBaseUrl+item.image}
                    className="ecchimage"
                    alt={`category ${key}`}
                   
                  />
                </div>
                <div className="col-10 m-auto">
                  <p className="pt-2 m-auto ecchtitle">{item.name}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
