import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux' 
import axios from "axios";
import { toast } from 'react-toastify';


function Forget() {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const toastId = toast.loading("Processing...", {
            style: { backgroundColor: '#3498db', color: '#fff' },
        });

        try {
            setLoading(true);
            const data = {
               email:email
              };
            await axios.post(`${apiUrl}/forgetPassword`, data).then((response) => {
                setLoading(false);
                setEmail('');
                if (response.data.success === true) {
                    toast.update(toastId, { render: `${response.data.message}`, type: "success", isLoading: false, autoClose: 3000 });
                } else {
                    toast.update(toastId, { render: `${response.data.message}`, type: "warning", isLoading: false, autoClose: 3000 });
                }
            })
        } catch (error) {
            toast.update(toastId, { render: `Failed_Error__${error}`, type: "error", isLoading: false, autoClose: 3000 });
            setLoading(false);
        }
      
    }    

    return <div className="container-fluid">
        <div className="row">
            <div className={`col-md-4 offset-md-4 mt-5 card bg-${mode} text-${modeText} shadow-lg p-3 mb-5 rounded`}>
                <h3 className="m-3  text-center">Forget Password</h3>

                <form  onSubmit={handleSubmit}>
                    <label htmlFor="email">Email</label>

                    <input className="form-control" type="email"  placeholder="Register Email" id="email" name="email" value=
                    {email}  required disabled={loading} readOnly={loading} style={{ opacity: loading ? 0.5 : 1 }} onChange={(e) => setEmail(e.target.value)} />
                    <br />

                    <button className="btn btn-success"  id="sbmtbtn" type="submit" disabled={loading} > Reset </button>
                </form>
                <div className=" mt-3">
                    <Link className={`alink text-${modeText} mt-3`} to="/login">
                       Back to login
                    </Link>
                </div>
            </div>
        </div>
    </div>;
}

export default Forget;