// PaymentComponent.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSelector } from "react-redux";

const PaymentComponent = ({ amount,onPaymentComplete,payfor,payforid }) => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const token = useSelector((state) => state.auth.user.token)
  const name = useSelector((state) => state.auth.user.name)
  const email = useSelector((state) => state.auth.user.email)

  // Load Razorpay script
  useEffect(() => {
    const loadRazorpay = () => {
      return new Promise((resolve) => {
        if (window.Razorpay) {
          resolve(window.Razorpay);
        } else {
          const script = document.createElement('script');
          script.src = 'https://checkout.razorpay.com/v1/checkout.js';
          script.onload = () => resolve(window.Razorpay);
          document.body.appendChild(script);
        }
      });
    };

    loadRazorpay().then((Razorpay) => {
      window.Razorpay = Razorpay;
    });
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    setError(null);

    try {
      // Step 1: Create the order
      const response = await axios.post(`${apiUrl}/create-order`, {
        amount: amount,
      }, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the Bearer token here
        }});

      if (response.data.success) {
        const orderId = response.data.order_id;

        // Step 2: Set up Razorpay options
        const options = {
          key: 'rzp_test_JnnBVTTL5wMR0t', // Replace with your Razorpay key
          amount: amount * 100, // Amount is in paise
          currency: 'INR',
          name: 'Sourav Tech',
          description: 'Test Transaction',
          order_id: orderId,
          handler: async (paymentResponse) => {
            setLoading(true);
            // Step 3: Verify the payment
            const verificationResponse = await axios.post(`${apiUrl}/verify-payment`, {
                razorpay_payment_id: paymentResponse.razorpay_payment_id,
                razorpay_order_id: paymentResponse.razorpay_order_id,
                razorpay_signature: paymentResponse.razorpay_signature,
                amount: amount, 
                payfor:payfor,
                payforid:payforid
            }, {
              headers: {
                Authorization: `Bearer ${token}`, // Add the Bearer token here
              },
            });
            setLoading(false);
            if (verificationResponse.data.success) {
               onPaymentComplete(verificationResponse.data);
              //alert('Payment successful and verified!');
            } else {
              alert('Payment verification failed!');
            }
          },
          prefill: {
            name: name,
            email: email,
            contact: '9999999999',
          },
          theme: {
            color: '#84F3F5',
          },
        };

        // Step 4: Open Razorpay payment modal
        const razorpay = new window.Razorpay(options);
        razorpay.open();

        razorpay.on('payment.failed', (response) => {
          console.log(response);
          alert('Payment failed');
        });
      } else {
        alert('Order creation failed');
      }
    } catch (error) {
      console.error(error);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button className="btn btn-success" onClick={handlePayment} disabled={loading}>
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PaymentComponent;
