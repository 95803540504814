import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import Home from "./Home";
import Navbar from "./Navbar";
import Register from "./auth/Register";
import Login from "./auth/Login";
import Forget from "./auth/Forget";
import My404Component from "./My404Component";
import Dashboard from "./dashboard/Dashboard";
//Notification container
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
//Toastify notification
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProtectedRoutes from "./utils/ProtectedRoute";
// import Exam from "./dashboard/Exam";
import ExamResult from "./dashboard/ExamResult";
// import Mocktest from "./dashboard/Mocktest";
import ExamStatic from "./dashboard/ExamStatic";
import ExamWithImage from "./dashboard/ExamWithImage";
import MocktestWithimage from "./dashboard/MocktestWithimage";
import Instruction from "./components/Instruction";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import UsersPolicy from "./pages/UsersPolicy";
import RefundAndCancellation from "./pages/RefundAndCancellation";
import HomeContent from "./HomeContent";
import Links from "./pages/Links"; 
import Footer from "./pages/Footer"; 
import Test from "./tests/Test";

import NewMocktest from "./dashboard/NewMocktest";


import ExamDetails from "./pages/ExamDetails";
import ExamSets from "./pages/ExamSets";
import Profile from "./pages/Profile"; 
import PurchaseList from "./pages/PurchaseList"; 
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ExamHistory from "./pages/ExamHistory"; 


function App() {
  const [mode, setMode] = useState(""); //for day & night mode
  const [modeText, setModeText] = useState(""); //for day & night mode textColor
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const hideNavbarRoutes = ["/exam", "/mocktest"];

  //check mode is store or not
  useEffect(() => {
    const storedMode = localStorage.getItem("themeBody");
    const storedModeText = localStorage.getItem("themeText");

    if (storedMode && storedModeText) {
      setMode(storedMode);
      setModeText(storedModeText);
    } else {
      // Default mode
      setMode("light");
      setModeText("dark");
    }
    checkLogin();
  }, []); // Run once on component mount

  //check login
  const checkLogin = async () => {
    let accessToken = localStorage.getItem("accessToken");
    // let refreshToken = localStorage.getItem("refreshToken");
    if (accessToken) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  //toggle theme
  // const toggleMode = () => {
  //   if (mode === "dark") {
  //     setMode("light");
  //     setModeText("dark");
  //     localStorage.setItem("themeBody", "light");
  //     localStorage.setItem("themeText", "dark");
  //   } else {
  //     setMode("dark");
  //     setModeText("light");
  //     localStorage.setItem("themeBody", "dark");
  //     localStorage.setItem("themeText", "light");
  //   }
  // };

  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        {/* <Navbar mode={mode} modeText={modeText} toggleMode={toggleMode} /> */}
        {!hideNavbarRoutes.includes(window.location.pathname) && (
          <Navbar />
        )}
        <Routes>
          {/* <Route exact path="/" element={<h1>Home Page</h1>} /> */}
          <Route
            exact
            path="/"
            element={<Home />}
          />
          <Route
            exact
            path="/home"
            element={<HomeContent />}
          />
          <Route
            exact
            path="register"
            element={<Register />}
          />
          <Route
            exact
            path="login"
            element={<Login checkLogin={checkLogin} />}
          />
          <Route
            exact
            path="forget"
            element={<Forget />}
          />


    {/* //---Protected routes start------ */}

      <Route element={<ProtectedRoutes />}>
            <Route
              exact
              path="dashboard"
              element={<Dashboard isLoggedIn={isLoggedIn} />}
            />
            <Route
              exact
              path="/examwithimage"
              element={<ExamWithImage isLoggedIn={isLoggedIn} />}
            />
            <Route
              exact
              path="examStatic"
              element={<ExamStatic />}
            />
            <Route
              exact
              path="examResult"
              element={<ExamResult isLoggedIn={isLoggedIn} />}
            />
            <Route
              exact
              path="mocktestwithimage"
              element={<MocktestWithimage />}
            />

            <Route
              exact
              path="instructions"
              element={<Instruction />}
            />

          
          <Route
              exact
              path="/ExamSets/:id"
              element={<ExamSets />}
            />

          <Route
              exact 
              path="/myprofile"
              element={<Profile />}
            />  

          <Route
              exact  
              path="/purchase"
              element={<PurchaseList />}
            />  

          <Route 
              exact 
              path="/mocktest"
              element={<NewMocktest />}  
          />

          <Route 
              exact 
              path="/history"
              element={<ExamHistory />}  
          />
          
      </Route>

      {/* -----Protected route end----- */}

          <Route
              exact
              path="/examDetails/:id"
              element={<ExamDetails />}
            />


          <Route
              exact
              path="about"
              element={<AboutUs />}
            />

          <Route
              exact
              path="contact"
              element={<ContactUs />}
            />

          <Route
              exact
              path="privacy-policy"
              element={<PrivacyPolicy />}
            />

          <Route
              exact
              path="terms-and-condition"
              element={<TermsAndConditions />}
            />

          <Route
              exact
              path="user-policy"
              element={<UsersPolicy />}
            />

          <Route
              exact
              path="refund-and-cancellation"
              element={<RefundAndCancellation />}
            />

          <Route 
              exact 
              path="/reset-password"
              element={<ResetPasswordPage />}  
          />

            <Route
              exact
              path="links"
              element={<Links />}
            />

          <Route
              exact
              path="test"
              element={<Test />}
            />

          <Route
            path="*"
            exact={true}
            element={<My404Component />}
          />
        </Routes>
        <Footer />
        <NotificationContainer />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
