//-------------------------------- USING LOCAL STORAGE ---------------------------

import { configureStore } from '@reduxjs/toolkit';
import themeReducer from './theme/themeSlice';
import authReducer from './auth/authSlice';

// Load state from local storage
const persistedState = localStorage.getItem('reduxState')
  ? JSON.parse(localStorage.getItem('reduxState'))
  : {};

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
  },
  preloadedState: persistedState, // Load persisted state
});

// Save state to local storage on every Redux state change
store.subscribe(() => {
  localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});

export default store;




//-----------------------NORMAL REDUX------------------------------

// import { configureStore } from '@reduxjs/toolkit'
// import themeReducer from './theme/themeSlice'
// import authReducer from './auth/authSlice'

// export const store = configureStore({
//   reducer: {
//     theme: themeReducer,
//     auth: authReducer,
//   },
// })