import React, { useState, useEffect } from "react";
import Loader from "./components/Loader";
import "./home.css";
import "./components/common_component.css";
import { useSelector } from "react-redux";
// import { toggleTheme } from "./redux/theme/themeSlice";
import QuizCard from "./components/QuizCard";
import { ExamCategoryCard, ExamCategoryCardHorizontal } from "./components/ExamCategoryCard";
import axios from 'axios';
import { Link } from "react-router-dom";
import examNames from "./json/examNames.json";
// import PaymentComponent from './components/PaymentComponent';
import ApiLoading from './components/ApiLoading';
import ApiError from './components/ApiError';
import ApiNodata from './components/ApiNodata';
import SubscribeUs from "./components/SubscribeUs";

function HomeContent() {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);

  //const dispatch = useDispatch(); // To dispatch actions

  const fetchCategories = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${apiUrl}/ExamCategory`);
      setCategories(response.data);
    } catch (error) {
      setError('Error fetching categories.');
     // console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
    {isLoading ? (
      <Loader />
    ) : (
        <>
        <div className={`home-container-fluid-home m-3 bg-${mode}`}>
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src="/image/banner.webp" className="img-fluid" alt="banner" />
            </div>
            <div className="col-md-8  text-center">
            <h1 className={`display-4 mb-4 fw-normal animate__animated animate__bounce text-${modeText}`}>Ace Your Exams with Upgrade</h1>
                <p className={`lead mb-4 fst-italic text-${modeText}`}>Practice makes perfect. Take realistic mock tests and boost your confidence.</p>
                <Link to="/login" className={`btn btn-outline-primary btn-lg  animate__animated animate__heartBeat`}>Start Practicing Now</Link>
            </div>
          </div>
        </div>

        <h4 className="text-center mt-2">Select Your Exam Category </h4>
        <p className="text-center">
          Click to view all available test series in an exam
        </p>

        {loading && <ApiLoading message={'Loading categories...'} />}
        {error && <ApiError  message={error} /> }
        {!loading && !error && categories.length === 0 && (
          <ApiNodata message="No data available for categories" />
        )}
        <ExamCategoryCard data={categories} />

        <h4 className="text-center">Explore Our All Mock Test</h4>
        <ExamCategoryCardHorizontal data={examNames} />
        <br />
        {/* <div className="card">
          <PaymentComponent amount={100} />
        </div> */}

        <br />

        <QuizCard />


{/* ----------Why choose us ----------------- */}

    <section className="py-5 my-2 ">
        <div className="container">
            <div className="row align-items-center">
                <div className={`col-md-4 card p-5  bg-${mode} text-${modeText}`}>
                    <h2 className="mb-4">Why Choose Upgrade?</h2>
                    <ul className="list-unstyled ">
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Realistic exam simulations</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Detailed performance analytics</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Customizable practice sessions</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Expert-curated question bank</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Mobile-friendly interface</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Track Your Progress</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Adaptive Difficulty</li>
                        <li className="mb-3 cardN"><i className="fas fa-check-circle text-success me-2"></i> Pocket friendly</li>
                    </ul>
                </div>
                <div className="col-md-4 text-center d-none d-md-block p-5">
                    <img src="image/whyChoose.webp" alt="Why Choose Us" className={`img-fluid rounded mb-4 mb-md-0  bg-${mode} text-${modeText}`} />
                </div>
                <div className="col-md-4">
                  <div className="container mt-5">
                    {/* <h2 className="text-center mb-4">Why Choose QuizMaster?</h2> */}
                        <div className="row">
                            <div className="mb-2 cardN">
                                <div className={`card h-100 shadow  bg-${mode} text-${modeText}`}>
                                    <div className="card-body text-center">
                                        <i className="bi bi-book feature-icon mb-3"></i>
                                        <h5 className="card-title">Diverse Topics</h5>
                                        <p className="card-text">Explore quizzes on history, science, pop culture, and more. There's something for everyone!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 cardN">
                                <div className={`card h-100 shadow  bg-${mode} text-${modeText}`}>
                                    <div className="card-body text-center">
                                        <i className="bi bi-graph-up feature-icon mb-3"></i>
                                        <h5 className="card-title">Adaptive Difficulty</h5>
                                        <p className="card-text">Our quizzes adjust to your skill level, ensuring a challenging and enjoyable experience for all users.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 cardN">
                                <div className={`card h-100 shadow  bg-${mode} text-${modeText}`}>
                                    <div className="card-body text-center">
                                        <i className="bi bi-bar-chart feature-icon mb-3"></i>
                                        <h5 className="card-title">Track Progress</h5>
                                        <p className="card-text">Monitor your improvement over time with detailed statistics and performance insights.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


{/* --------------Testimonial----------- */}

    <section className={`py-5 bg-light  bg-${mode} text-${modeText}`}>
        <div className="container-fluid ">
            <h2 className="text-center mb-5">What Our Users Say</h2>
            <div className="row">
                <div className="col-md-3 mb-4 cardN">
                    <div className="card testimonial-card fade-in">
                        <div className={`card-body bg-${mode} text-${modeText}`}>
                            <div className="d-flex align-items-center mb-3">
                                <img src="myicons/user.png" alt="Sarah J." className="rounded-circle avatar me-3" />
                                <div>
                                    <h5 className="card-title mb-0">Arjun Patel</h5>
                                    <div className="star-rating">
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <p className="card-text">"The mock tests helped me stay focused and gave me the confidence I needed to crack JEE. The questions were spot on with the latest pattern!"</p>
                            <span className={`achievement-badge border border-success p-1 rounded bg-${mode} text-${modeText}`}>JEE Aspirant</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-4 cardN">
                    <div className="card testimonial-card fade-in">
                        <div className={`card-body bg-${mode} text-${modeText}`}>
                            <div className="d-flex align-items-center mb-3">
                                <img src="myicons/user.png" alt="Sarah J2." className="rounded-circle avatar me-3" />
                                <div>
                                    <h5 className="card-title mb-0">Priya Sharma</h5>
                                    <div className="star-rating">
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <p className="card-text">"Thanks to the practice tests and resources, I cleared NEET with a great score! This platform truly understands the syllabus and exam patterns."</p>
                            <span className={`achievement-badge border border-success p-1 rounded bg-${mode} text-${modeText}`} >NEET Topper</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-4 cardN">
                    <div className="card testimonial-card fade-in">
                        <div className={`card-body bg-${mode} text-${modeText}`}>
                            <div className="d-flex align-items-center mb-3">
                                <img src="myicons/user.png" alt="Mike R." className="rounded-circle avatar me-3" />
                                <div>
                                    <h5 className="card-title mb-0">Rohit Kumar</h5>
                                    <div className="star-rating">
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                    </div>
                                </div>
                            </div>
                            <p className="card-text">"I was struggling with time management for UPSC preparation. The timed mock tests really improved my speed and accuracy."</p>
                            <span className={`achievement-badge border border-success p-1 rounded bg-${mode} text-${modeText}`}>UPSC Aspirant</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mb-4 cardN">
                    <div className="card testimonial-card fade-in">
                        <div className={`card-body bg-${mode} text-${modeText}`}>
                            <div className="d-flex align-items-center mb-3">
                                <img src="myicons/user.png" alt="Emily T." className="rounded-circle avatar me-3" />
                                <div>
                                    <h5 className="card-title mb-0">Ananya Singh</h5>
                                    <div className="star-rating">
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="fas fa-star text-warning"></i>
                                        <i className="far fa-star"></i>
                                    </div>
                                </div>
                            </div>
                            <p className="card-text">"The platform’s practice tests for CAT were amazing! They helped me identify my weak areas and gave me the practice I needed to ace the exam."</p>
                            <span className={`achievement-badge border border-success p-1 rounded bg-${mode} text-${modeText}`} >CAT Aspirant</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>




    
{/* ------- Counter -------- */}


<div className="container mt-5">
        <div className="row text-light">
            <div className="four col-md-3 mb-2">
                <div className="counter-box" style={{backgroundColor:"#754480"}}>
                    <i className="fa-regular fa-thumbs-up"></i>
                    <span className="counter">2147</span>
                    <p className="boxtext">Happy Customers</p>
                </div>
            </div>
            <div className="four col-md-3 mb-2">
                <div className="counter-box" style={{backgroundColor:"#5A4FCF"}}>
                    <i className="fa-solid fa-user-group"></i>
                    <span className="counter">3275</span>
                    <p className="boxtext">Registered Members</p>
                </div>
            </div>
            <div className="four col-md-3 mb-2">
                <div className="counter-box" style={{backgroundColor:"#0093AF"}}>
                    <i className="fa-solid fa-cart-shopping"></i>
                    <span className="counter">289</span>
                    <p className="boxtext">Available Products</p>
                </div>
            </div>
            <div className="four col-md-3 mb-2">
                <div className="counter-box" style={{backgroundColor:"#646400"}}>
                    <i className="fa-solid fa-user"></i>
                    <span className="counter">1563</span>
                    <p className="boxtext">Sales</p>
                </div>
            </div>
        </div>	
    </div>



{/* -------------How it works-------------------  */}
   <div className="container mt-5">
        <h2 className="text-center mb-4">How It Works</h2>
        <div className="row">
            <div className="col-md-3 mb-4 cardN">
                <div className="card h-100 shadow">
                    <div className={`card-body text-center bg-${mode} text-${modeText}`}>
                        <i className="bi bi-person-plus feature-icon mb-3"></i>
                        <h5 className="card-title">1. Sign Up</h5>
                        <p className="card-text">Create your free account to access all features and track your progress.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4 cardN">
                <div className="card h-100 shadow">
                    <div className={`card-body text-center bg-${mode} text-${modeText}`}>
                        <i className="bi bi-search feature-icon mb-3"></i>
                        <h5 className="card-title">2. Choose a Catrgory</h5>
                        <p className="card-text">Select from our wide range of topics or let us suggest one for you.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4 cardN">
                <div className="card h-100 shadow">
                    <div className={`card-body text-center bg-${mode} text-${modeText}`}>
                        <i className="bi bi-pencil-square feature-icon mb-3"></i>
                        <h5 className="card-title">3. Take the Mocktets/Quiz</h5>
                        <p className="card-text">Answer questions and challenge yourself to improve your score.</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4 cardN">
                <div className="card h-100 shadow">
                    <div className={`card-body text-center bg-${mode} text-${modeText}`}>
                        <i className="bi bi-trophy feature-icon mb-3"></i>
                        <h5 className="card-title">4. Get Results</h5>
                        <p className="card-text">Review your performance, learn from mistakes, and track your progress.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>







{/* ------------Join us -------- */}


    <div className={`container-fluid card mt-3 p-5 bg-${mode} text-${modeText}`}>
        <div className="row align-items-center">
            <div className="col-lg-6">
                <h2 className="mb-4 text-center">Join Our Community</h2>
                <p className="lead mb-4 text-center">Connect with fellow quiz enthusiasts, take daily mock tests and challenges, and climb the leaderboard!</p>
                <ul className="list-unstyled">
                    <li><i className="fas fa-check-circle text-primary me-2"></i> Compete in weekly tournaments</li>
                    <li><i className="fas fa-check-circle text-primary me-2"></i> Create and share your own quizzes</li>
                    <li><i className="fas fa-check-circle text-primary me-2"></i> Earn badges and achievements</li>
                    <li><i className="fas fa-check-circle text-primary me-2"></i> Join topic-specific study groups</li>
                    <li><i className="fas fa-check-circle text-primary me-2"></i> Free Mocktest & quizs</li>
                </ul>
                <Link className={`btn btn-primary btn-lg mt-3 rounded-0 btn-sm`} to="/register">Join Now</Link>
            </div>
            <div className="col-lg-6  text-center">
                <img src="logo.png?height=400&width=600" alt="Community Illustration" className="img-fluid rounded shadow cardN" />
            </div>
        </div>
    </div>



        <SubscribeUs />
        </>
      )}
    </>
  );
}

export default HomeContent;
