import { useLocation,useNavigate } from 'react-router-dom';

function Instruction() {
    const location = useLocation();
    const navigate = useNavigate();

    // Function to get query parameters
    // const queryParams = new URLSearchParams(location.search);
    // const goto = queryParams.get('goto');
    // const id = queryParams.get('id');

    const { id, goto } = location.state || {};

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate(`/${goto}`, {
          state: { id: id, agree: 'on' }, // Pass data using state
        });
      };
    


    return <div className="container-fluid mt-3">
        <p className="p01 text-center p-2 h5" style={{ backgroundColor: 'lightslategray' }}>
        <span className="badge rounded-pill bg-danger" style={{cursor:'pointer'}} onClick={() => navigate(-1)}><i className="fa-solid fa-left-long"></i>  Back</span> &nbsp;&nbsp;
        Instructions
        </p>
        <div className="card hadow-lg p-3 mb-5 bg-body rounded">
            <p className="fst-italic">Please read the instructions carefully</p>
            <p>General Instructions :</p>
            <div>
                <ol>
                    <li className="mb-2">
                        Total duration of <b>COMPUTER SCIENCE_INFORMATICS PRACTICES</b> Papers is <b>45 min</b>.
                    </li>
                    <li className="mb-2">The Questions Palette displayed on the right side of screen will show the status of each question using one of the following symbols :    </li>
                    <div className="row text-center">
                        <div className="col-md-4 text-md-right">
                            <ul className="list-group list-group-horizontal mt-1">
                                <li className="list-group-item"><span className="colorDefinationShape notvisted"></span> Not visited</li>
                                <li className="list-group-item"><span className="colorDefinationShape visited"></span> visited</li>
                                <li className="list-group-item"><span className="colorDefinationShape attempted"></span> Attempt</li>
                                <li className="list-group-item"><span className="colorDefinationShape skipped"></span> skip</li>
                            </ul>
                        </div>
                        <div className="col-md-8 text-md-left">
                            <ul className="list-group list-group-horizontal mt-1">
                                <li className="list-group-item"><span className="colorDefinationShape marked"></span> marked for review</li>
                                <li className="list-group-item"><span className="colorDefinationShape markForReview"></span>  Save & marked for review</li>
                                <li className="list-group-item"><span className="colorDefinationShape currentQuestion"></span> Current question</li>
                            </ul>
                        </div>
                    </div>



                    <li className="mb-2">You can click on the {'>'} arrow which appears to the left of the question palette to collapse it, thereby maximizing the question window. To view the question palette again, you can click on {'<'} which appears on the right side of the question window.</li>


                    <li className="mb-2">You can click on your "Profile" image on top right corner of your screen to change the language during the exam for entire question paper. On clicking of Profile image you will get a drop-down to change the question content to the desired language.</li>
                    <li className="mb-2">You can click on  to navigate to the bottom and  to navigate to top of the question are, without scrolling.</li>
                    <li className="mb-2">To answer a question, do the following:
                        <ol>
                            <li className="mb-2">Click on the question number in the Question Palette at the right of your screen to go to that numbered question directly. Note that using this option does NOT save your answer to the current question.</li>
                            <li className="mb-2">Click on Save & Next to save your answer for the current question and then go to the next question.</li>
                            <li className="mb-2">Click on Mark for Review & Next to save your answer for the current question, mark it for review, and then go to the next question.</li>
                        </ol>
                    </li>

                    <li className="mb-2">Procedure for answering a multiple choice type question:
                        <ol>
                            <li className="mb-2">To select you answer, click on the button of one of the options.</li>
                            <li className="mb-2">To deselect your chosen answer, click on the button of the chosen option again or click on the Clear Response button</li>
                            <li className="mb-2">To change your chosen answer, click on the button of another option</li>
                            <li className="mb-2">To save your answer, you MUST click on the Save & Next button.</li>
                            <li className="mb-2">To mark the question for review, click on the Mark for Review & Next button.</li>
                        </ol>
                    </li>


                    <li className="mb-2">To change your answer to a question that has already been answered, first select that question for answering and then follow the procedure for answering that type of question.</li>

                    <li className="mb-2">Sections in this question paper are displayed on the top bar of the screen. Questions in a section can be viewed by click on the section name. The section you are currently viewing is highlighted.</li>

                    <li className="mb-2">After click the Save & Next button on the last question for a section, you will automatically be taken to the first question of the next section.</li>

                    <li className="mb-2">You can shuffle between sections and questions anything during the examination as per your convenience only during the time stipulated.</li>
                    <li className="mb-2">Candidate can view the corresponding section summery as part of the legend that appears in every section above the question palette.</li>

                </ol>
                <p className="text-danger">Please note all questions will appear in your default language. This language can be changed for a particular question later on.</p>

                <div>
                    <form method="get" onSubmit={handleSubmit}>
                        <input type="hidden" name="id" value={id} readOnly required />
                        <input type="checkbox" name="agree" required /> &nbsp;I have read and understood the instructions. All computer hardware allotted to me are in proper working condition. I declare that I am not in possession of / not wearing / not carrying any prohibited gadget like mobile phone, bluetooth devices etc. /any prohibited material with me into the Examination Hall.I agree that in case of not adhering to the instructions, I shall be liable to be debarred from this Test and/or to disciplinary action, which may include ban from future Tests / Examinations
                        <br />
                        <button type="submit" className="btn btn-success mt-3">Proceed</button>
                    </form>
                </div>

            </div>
        </div>

    </div>
}

export default Instruction;