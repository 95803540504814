import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { logout } from './redux/auth/authSlice'
import { toggleTheme } from './redux/theme/themeSlice';

function Navbar() {
  const dispatch = useDispatch(); // To dispatch actions
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const logOutFunction = async () => {
    localStorage.removeItem('accessToken');
    dispatch(logout());  //redux
    window.location.href = '/login';
  }

  const changeTheme = () => {
    dispatch(toggleTheme());
  }

  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <>
      <nav
        className={`navbar sticky-top shadow-lg navbar-expand-lg bg-${mode} navbar-${mode}`}
      >
        <div className="container-fluid">
          <Link to="/home" className="navbar-brand">
            <img
              src="/logo.png"
              alt=""
              width="100"
              height="60"
              className="d-inline-block align-text-top"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon text-primary"></span>
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end ${!isNavCollapsed ? true : false}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ms-auto gap-3">
              <li
                className="nav-item"
                onClick={() => {
                  changeTheme();
                  handleNavCollapse();
                }}
                style={{cursor:'pointer'}}
              >
                <i
                  className={`fa-solid fa-circle-half-stroke text-${modeText}`}
                  title="Day/Night mode"
                ></i>
              </li>
              <li className="nav-item">
                <Link
                  className={`alink text-${modeText}`}
                  aria-current="page"
                  to="/home"
                  onClick={handleNavCollapse}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`alink text-${modeText}`}
                  to="/about"
                  onClick={handleNavCollapse}
                >
                  About us
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`alink text-${modeText}`}
                  to="/contact"
                  onClick={handleNavCollapse}
                >
                  Contact us
                </Link>
              </li>



              {isAuthenticated ? (
                <>

                  <li className="nav-item">
                    <Link
                      className={`alink text-${modeText}`}
                      to="/dashboard"
                      onClick={handleNavCollapse}
                    >
                      Dashboard
                    </Link>
                  </li>

                  <li className="nav-item" >
                    <button className="btn btn-outline-danger" onClick={logOutFunction}>
                      Logout
                    </button>
                  </li>

                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link
                      className={`alink text-${modeText}`}
                      to="/register"
                      onClick={handleNavCollapse}
                    >
                      Register
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`alink text-${modeText}`}
                      to="/login"
                      onClick={handleNavCollapse}
                    >
                      Login
                    </Link>
                  </li>
                </>
              )}

            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
