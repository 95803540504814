import React from "react";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { useLocation } from "react-router-dom";

function ExamResult(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const resultData = location.state;

  const handleTryAgain = () => {
    navigate("/exam");
  };

  const handleDownload = () => {
    NotificationManager.success("", "Download started");
  };

  const handleGoToHome = () => {
    navigate("/Dashboard");
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className={`card  bg-${props.mode} text-${props.modeText}`}>
            <div className="card-header">
              <p className="text-center">Exam Result</p>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  {" "}
                  <table className={`table table-striped table-hover ${props.mode=='dark'?'table-dark':''}`}>
                    <tbody>
                      <tr>
                        <td>Name : </td>
                        <td>Sourav k</td>
                      </tr>
                      <tr>
                        <td>Exam name : </td>
                        <td>Quiz</td>
                      </tr>
                      <tr>
                        <td>Date : </td>
                        <td>09-05-2024 03:31 PM</td>
                      </tr>
                      <tr>
                        <td>Result</td>
                        <td>
                          {resultData.correctAnswers} /{" "}
                          {resultData.totalQuestions}
                        </td>
                      </tr>
                      <tr>
                        <td>Grade</td>
                        <td>A+</td>
                      </tr>
                      <tr className="text-center">
                        <td>
                          <button onClick={handleTryAgain}>
                            <i className="fa-solid fa-rotate"></i> Try again
                          </button>
                        </td>
                        <td>
                          <button onClick={handleDownload}>
                            <i className="fa-solid fa-download"></i> Download
                            result
                          </button>
                        </td>
                      </tr>
                     
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  {/* <div>
                    <h4>Exam Result</h4>
                    <p>Total Questions: {resultData.totalQuestions}</p>
                    <p>Attempted Questions: {resultData.attemptedQuestions}</p>
                    <p>Skipped Questions: {resultData.skippedQuestions}</p>
                    <p>Correct Answers: {resultData.correctAnswers}</p>
                    <p>Incorrect Answers: {resultData.incorrectAnswers}</p>
                  </div> */}
                  <table  className={`table table-striped table-hover ${props.mode=='dark'?'table-dark':''}`}>
                    <tbody>
                      <tr>
                        <td>Total Questions:</td>
                        <td>{resultData.totalQuestions}</td>
                      </tr>
                      <tr>
                        <td>Attempted Questions:</td>
                        <td>{resultData.attemptedQuestions}</td>
                      </tr>
                      <tr>
                        <td>Skipped Questions:</td>
                        <td>{resultData.skippedQuestions}</td>
                      </tr>
                      <tr>
                        <td>Correct Answers:</td>
                        <td>{resultData.correctAnswers}</td>
                      </tr>
                      <tr>
                        <td>Incorrect Answers:</td>
                        <td>{resultData.incorrectAnswers}</td>
                      </tr>
                      <tr className="text-center">
                        <td colSpan={2}>
                          <button onClick={handleGoToHome}>
                            <i className="fa-solid fa-house"></i> Go to home
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamResult;
