import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  mode: 'light',
  modeText: 'dark'
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.mode = state.mode === 'light' ? 'dark' : 'light';
      state.modeText = state.mode === 'light' ? 'dark' : 'light';
    },

  },
})


export const { toggleTheme } = themeSlice.actions

export default themeSlice.reducer