import { useSelector } from "react-redux";

export default function AboutUs() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return <div className={`container mt-1 card p-3 bg-${mode} text-${modeText} `}>
        <h4 className="text-center mb-3"> Welcome to Upgrade</h4>
        <p>At Upgrade, we are dedicated to providing a comprehensive platform for students and professionals to excel in their academic and career pursuits. Our mission is to empower learners with effective tools and resources to succeed in competitive exams and assessments.</p>

        <b>Our Story -</b>
        <p>Founded in 2024, Upgrade emerged from a collective vision to revolutionize the way individuals prepare for exams. Our team of educators, technologists, and industry experts came together with a shared goal: to create a user-friendly platform that offers high-quality mock tests across a wide range of subjects and disciplines.</p>

        <b>What We Offer -</b>
        <p>Upgrade offers a diverse array of mock tests tailored to various educational levels and competitive exams. Whether you're preparing for college entrance exams, professional certifications, or government recruitment tests, we've got you covered. Our platform features:</p>
        <ul>
            <li><b>Comprehensive Test Bank:</b> Access thousands of mock tests covering subjects such as mathematics, science, language arts, computer science, and more.</li>
            <li><b>Customizable Practice:</b> Tailor your study experience with customizable tests to focus on specific topics or areas of weakness.</li>
            <li><b>Realistic Exam Simulation:</b> Experience exam-like conditions with timed tests and question formats that closely mirror the actual exams.</li>
            <li><b>Detailed Performance Analytics:</b> Track your progress with insightful analytics and performance metrics to identify strengths and areas for improvement.</li>
        </ul>


        <b>Why Choose Us -</b>
        <p>At Upgrade, we prioritize quality, accessibility, and user satisfaction. Here's why thousands of learners trust us:</p>
        <ul>
            <li><b>Quality Content:</b> Our tests are meticulously curated by subject matter experts to ensure accuracy and relevance.</li>
            <li><b>User-Friendly Interface:</b> Navigate our platform effortlessly with intuitive design and seamless functionality.</li>
            <li><b>Flexibility:</b> Study anytime, anywhere, with 24/7 access to our online platform from any device.</li>
            <li><b>Customer Support:</b> Our dedicated support team is here to assist you every step of the way, addressing any queries or concerns promptly.</li>
        </ul>


        <b>Join Our Community -</b>
        <p>Join the Upgrade community today and embark on your journey to success. Whether you're aiming for academic excellence or professional advancement, we're here to support you every step of the way. Start your free trial now and experience the difference with Upgrade!</p>
    </div>;
}