import React, { useState,useEffect } from "react";
import { NotificationManager } from "react-notifications";
import { useNavigate, Link, useLocation  } from "react-router-dom";
import queryString from 'query-string';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../redux/auth/authSlice';
import { GoogleLogin } from "@react-oauth/google";
import Loader from "../components/Loader";

function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const currentParams = queryString.parse(location.search); // Get current query parameters
  const { id,type,goto }  = currentParams;
 // Construct the query string for the link
  const queryStringParams = queryString.stringify(currentParams);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errmessage, setErrMessage] = useState("");
  const [successmessage, setSuccessMessage] = useState("");


  const dispatch = useDispatch();
  const mode = useSelector((state) => state.theme.mode);
  const modeText = useSelector((state) => state.theme.modeText);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    if (isAuthenticated) {
      if (id && type && goto) {
        navigate(`${goto}?alreadyLogin=true`);
      } else {
        navigate("/dashboard?alreadyLogin");
      }
    }
  }, [isAuthenticated, id, type, goto, navigate]);


  const handleSubmit = (event) => {
    event.preventDefault();
    setErrMessage("");

    if (username === "") {
      setErrMessage("Please input Username");
      return;
    }
    if (password === "") {
      setErrMessage("Please input password");
      return;
    }

    // Disable the button
    document.getElementById("sbmtbtn").disabled = true;

    setLoading(true);
    document.getElementById("sbmtbtn").disabled = false;
    // Perform axios request here
    const data = {
      email: username,
      password: password,
    };
    axios
      .post(`${apiUrl}/login`, data)
      .then((response) => {
        //console.log(response.data);
        setLoading(false);
        document.getElementById("sbmtbtn").disabled = false;
        if (response.data.success === true) {
          NotificationManager.success(
            "Redirecting to dashboard...",
            "Login success"
          );
          localStorage.setItem("accessToken", response.data.token);
    
          //redux 
          const userData = response.data.data;
          const token = response.data.token;
          dispatch(login({ user: userData, token }));

          if(id && type && goto){
            navigate(goto);
          }else{
           navigate("/dashboard");
          }
        }
      })
      .catch((error) => {
        // console.log(error.response.data);
        setLoading(false);
        document.getElementById("sbmtbtn").disabled = false;
        NotificationManager.error(error.response.data.message, "Error");
      });
  };



  //If the user is logged in, redirect to the dashboard
  // if (isAuthenticated){
  //   if(id && type && goto){
  //     navigate(`${goto}?alreadyLogin=true`);
  //   }else{
  //     navigate("/dashboard?alredayLogin");
  //   }
  // }

  //For google login
  const handleGoogleResponse = (credentialResponse) => { 
   // console.log(credentialResponse);
   setLoading(true);
    const tokenId = credentialResponse.credential; 
    axios
      .post(`${apiUrl}/google-login`, { tokenId })
      .then((response) => {
        setLoading(false);
        const userData = response.data.data;
        const token = response.data.token;
        localStorage.setItem("accessToken", token);
        dispatch(login({ user: userData, token }));
        navigate("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        NotificationManager.error(error.response.data.message, "Error");
      });
  };


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div
            className={`col-md-4 offset-md-4 mt-5 card bg-${mode} text-${modeText} shadow-lg p-3 mb-5 rounded`}
          >
            <h3 className="m-3  text-center">Login Here</h3>

            <form onSubmit={handleSubmit}>
              <label htmlFor="username">Username</label>
              <input
                className="form-control"
                type="text"
                placeholder="Email"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br />
              <label htmlFor="password">Password</label>
              <input
                className="form-control"
                type="password"
                placeholder="Password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <button
                className="btn btn-success mb-2"
                id="sbmtbtn"
                type="submit"
              >
                Log In
              </button>{" "}
              <br />
            </form>

             {/* Google Login Button */}
             <GoogleLogin
              onSuccess={handleGoogleResponse} 
              onFailure={() => NotificationManager.error("Google login failed", "Error")}
            />

            {loading &&  <Loader />  }

            <span className="text-danger">{errmessage}</span>
            <span
              className="text-success"
              dangerouslySetInnerHTML={{ __html: successmessage }}
            />

            <Link className={`alink text-${modeText} mt-3`}  to={`/register?${queryStringParams}`}>
              New here ? Register
            </Link>
            <Link className={`alink text-${modeText} mt-3`}  to={`/forget?${queryStringParams}`}>
              Forget password
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;