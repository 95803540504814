import React, { useState, useEffect } from "react";
import Loader from "./components/Loader";
import "./home.css";

import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 2 seconds
      navigate('/home'); 
    }, 2000);

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, [navigate]); 


  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="home-container-fluid-home">
             <p>Content loaded!</p>
          </div>
        </>
      )}
    </>
  );
}

export default Home;
