import React, { useState } from "react";
import Data from "./../questions.json";
const Test = () => {
  //const[arr,setArr]=useState([]);
  const [count, setCount] = useState(0);
  const [Question, setQuestion] = useState([]);

  useState(() => {
    setCount(count);
    setQuestion(Data[count]);
  }, [count]);

  const back = () => {
    // let arr = [];
    setCount(prevCount => prevCount - 1); 

    // showCount();
    // arr.push(Data[count]);
    // setQuestion(arr);
  }

  const  next = () => {
    // let arr = [];
    setCount(prevCount => prevCount + 1); 

    // showCount();
    // arr.push(Data[count]);
    // setQuestion(arr);
  }

  // function showCount() {
  //   console.log(count);
  // }

  return (
    <div>
      {Question.map((info) => {
        //const{id,question,A,B,C,D}=info;
        return (
          <div key={info.id}>
            <p>{count}</p>
            <h2>{info.question}</h2>
            <p>{info.A}</p>
            <p>{info.B}</p>
            <p>{info.C}</p>
            <p>{info.D}</p>
          </div>
        );
      })}

      <button onClick={back} disabled={count < 0 ? true : false}>
        BACK
      </button>
      <button onClick={next} disabled={count > Data.length - 1 ? true : false}>
        NEXT
      </button>
    </div>
  );
};

export default Test;
