import { useSelector } from "react-redux";

export default function PrivacyPolicy() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return <div className={`container mt-4 card p-2 bg-${mode} text-${modeText} `}>
        <h4 className="text-center mb-3"><u>Privacy Policy</u></h4><br />
        At Upgrade, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you visit our website or use our services.

        <br /> <br />

        <h5>Information We Collect</h5>

        <p><b>When you visit Upgrade or use our services, we may collect certain information about you, including:</b></p>

        <p>Personal information such as your name, email address, and contact details, which you provide voluntarily when registering an account or contacting us.</p>
        <p>Usage data such as your IP address, browser type, and operating system, collected automatically through cookies and similar tracking technologies.</p>
        <p>Information related to your interactions with our website, including the pages you visit, the links you click, and the time and date of your visits.</p>

        <br /><h5>How We Use Your Information</h5>

        <p><b>We may use the information we collect for the following purposes:</b></p>

        <p>To provide and maintain our services, including administering your account, processing transactions, and delivering personalized content.</p>
        <p>To improve our website and services, by analyzing usage trends, identifying areas for improvement, and optimizing user experience.</p>
        <p>To communicate with you, including responding to your inquiries, sending administrative messages, and providing updates about our services.</p>
        <p>To comply with legal obligations, enforce our terms and conditions, and protect the rights, property, and safety of Upgrade and our users.</p>

        <br /><h5>Data Security</h5>

        <p>We implement a variety of security measures to safeguard your personal information and protect it from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

        <br /><h5>Data Sharing</h5>

        <p>We may share your personal information with third-party service providers who assist us in operating our website, conducting our business, or servicing you. We may also share your information when required by law or to protect our rights or the rights of others.</p>

        <br /><h5>Your Rights</h5>

        <p>You have certain rights regarding your personal information, including the right to access, correct, or delete your data. If you would like to exercise any of these rights or have any questions about our Privacy Policy, please contact us at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>

        <br /><h5>Changes to This Policy</h5>

        <p>Upgrade reserves the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on this page. We encourage you to review this Privacy Policy periodically for any changes.</p>

        <br /><h5>Contact Us</h5>

        <p>If you have any questions or concerns about our Privacy Policy or our data practices, please contact us at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>
    </div>;
}