import { useSelector } from "react-redux";

export default function RefundAndCancellation() {
    const mode = useSelector((state) => state.theme.mode);
    const modeText = useSelector((state) => state.theme.modeText);

    return <div className={`container mt-4 card p-2 bg-${mode} text-${modeText} `}>
        <h4 className="text-center mb-3"><u>Refund And Cancellation</u></h4>
        <p> At Upgrade, we strive to provide high-quality services and ensure customer satisfaction. This Refund and Cancellation Policy outlines the terms and conditions governing refunds and cancellations for our mock test services.</p>

        <br /><h5>Refund Policy</h5>

        <p><b>We offer refunds under the following circumstances:</b></p>

        <p><u>Technical Issues</u> : If you encounter technical issues that prevent you from accessing or completing a mock test, and our support team is unable to resolve the issue within a reasonable timeframe, you may be eligible for a refund. Please contact our support team with details of the issue for further assistance.</p>

        <p><u>Duplicate Purchases</u> : If you accidentally purchase the same mock test multiple times, please contact us immediately to request a refund for the duplicate purchase. We may require proof of purchase to process the refund.</p>

        <p><u>Unsatisfactory Service</u> : If you are dissatisfied with the quality of our mock test services for any reason, you may request a refund within [number] days of the purchase date. Please provide specific feedback about the issues you encountered to help us improve our services in the future.</p>

        <br /><h5>Cancellation Policy</h5>

        <p>You may cancel your subscription or purchase of mock tests at any time by contacting our support team. Depending on the type of subscription or purchase, the following cancellation policies apply:</p>

        <p><u>Subscription Plans</u> : If you cancel your subscription plan before the end of the current billing cycle, you will retain access to the mock tests until the end of the billing period. No refunds will be provided for partial billing cycles.</p>

        <p><u>One-Time Purchases</u> : If you cancel a one-time purchase of mock tests, you will retain access to the purchased mock tests indefinitely. However, no refunds will be provided for canceled purchases.</p>

        <br /><h5>How to Request a Refund or Cancellation</h5>

        <p><b>To request a refund or cancellation, please contact our support team at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a> with the following information:</b></p>

        <p>Your name and email address associated with the account.</p>
        <p>Details of the purchase or subscription, including the transaction ID and date of purchase.</p>
        <p>Reason for the refund or cancellation request.</p>
        <p>Our support team will review your request and respond to you as soon as possible.</p>

        <br /><h5>Policy Changes</h5>

        <p>Upgrade reserves the right to revise this Refund and Cancellation Policy at any time without prior notice. Any changes will be effective immediately upon posting on this page.</p>

        <br /><h5>Contact Us</h5>
        <p>If you have any questions or concerns about our Refund and Cancellation Policy, please contact us at <a href="mailto:contact@upgrade.com">contact@upgrade.com</a>.</p>
    </div>;
}